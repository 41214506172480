// SitrepTemplateManager.js

import React, { useState } from 'react';
import {
  useSitrepTemplates,
  useCreateSitrepTemplate,
  useUpdateSitrepTemplate,
  useDeleteSitrepTemplate,
} from './hooks/useSitrepTemplates';
import { useSectionTemplates } from './hooks/useSectionTemplates';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import EntityList from 'components/IAP/EntityList';
import { useToggle } from 'react-use';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import SitrepTemplateEditDrawer from './SitrepTemplateEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

const SitrepTemplateManager = () => {
  const { data: sitrepTemplates = [], isLoading, isError } = useSitrepTemplates();
  const { data: sectionTemplates = [] } = useSectionTemplates();
  const createSitrepTemplateMutation = useCreateSitrepTemplate();
  const updateSitrepTemplateMutation = useUpdateSitrepTemplate();
  const deleteSitrepTemplateMutation = useDeleteSitrepTemplate();

  const [addDrawerOpen, toggleAddDrawer] = useToggle(false);
  const [editDrawerOpen, toggleEditDrawer] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [expanded, toggleExpanded] = useToggle(true);

  const handleOpenAddDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer();
  };

  const handleOpenEditDrawer = (template) => {
    setSelectedTemplate(template);
    toggleEditDrawer();
  };

  const handleCloseDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer(false);
    toggleEditDrawer(false);
  };

  const handleSaveTemplate = (templateData) => {
    if (selectedTemplate) {
      // Update existing template
      updateSitrepTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('SITREP template updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating SITREP template:', error);
          toast.error('Failed to update SITREP template.', toastConfig);
        },
      });
    } else {
      // Create new template
      createSitrepTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('SITREP template created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating SITREP template:', error);
          toast.error('Failed to create SITREP template.', toastConfig);
        },
      });
    }
  };

  const handleDeleteTemplate = (id) => {
    if (window.confirm('Are you sure you want to delete this SITREP Template?')) {
      deleteSitrepTemplateMutation.mutate(id, {
        onSuccess: () => {
          toast.success('SITREP template deleted successfully.', toastConfig);
        },
        onError: (error) => {
          console.error('Error deleting SITREP template:', error);
          toast.error('Failed to delete SITREP template.', toastConfig);
        },
      });
    }
  };

  if (isError) {
    return <div className="error-message">Error loading SITREP templates.</div>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <StylishNewButton onClick={handleOpenAddDrawer}>
          <MdAdd className="me-2" />
          Add SITREP Template
        </StylishNewButton>
      </div>
      {isLoading ? (
        <div>Loading SITREP Templates...</div>
      ) : (
        <EntityList
          entities={sitrepTemplates}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          renderEntity={(template) => (
            <div
              key={template.id}
              className="d-flex justify-content-between align-items-center p-2 border-bottom"
            >
              <div>
                <strong>{template.name}</strong>
                <ol>
                  {template.sections.map((section, index) => {
                    const foundSection = sectionTemplates.find((st) => st.id === section.section_id);
                    return <li key={index}>{foundSection ? foundSection.name : 'Unknown Section'}</li>;
                  })}
                </ol>
              </div>
              <div>
                <StylishNewButton
                  className="btn-icon me-2"
                  onClick={() => handleOpenEditDrawer(template)}
                >
                  <MdEdit />
                </StylishNewButton>
                <StylishNewButton
                  className="btn-icon"
                  onClick={() => handleDeleteTemplate(template.id)}
                >
                  <MdDelete />
                </StylishNewButton>
              </div>
            </div>
          )}
        />
      )}

      {/* Add/Edit Drawer */}
      {(addDrawerOpen || editDrawerOpen) && (
        <SitrepTemplateEditDrawer
          show={addDrawerOpen || editDrawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveTemplate}
          template={selectedTemplate}
        />
      )}
    </div>
  );
};

export default SitrepTemplateManager;

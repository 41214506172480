// CustomDataSourceManager.js

import React, { useState } from 'react';
import {
  useCustomDataSources,
  useCreateCustomDataSource,
  useUpdateCustomDataSource,
  useDeleteCustomDataSource,
} from './hooks/useCustomDataSources';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import EntityList from 'components/IAP/EntityList';
import { useToggle } from 'react-use';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import CustomDataSourceEditDrawer from './CustomDataSourceEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

const CustomDataSourceManager = () => {
  const { data: customDataSources = [], isLoading, isError } = useCustomDataSources();
  const createCustomDataSourceMutation = useCreateCustomDataSource();
  const updateCustomDataSourceMutation = useUpdateCustomDataSource();
  const deleteCustomDataSourceMutation = useDeleteCustomDataSource();

  const [drawerOpen, toggleDrawer] = useToggle(false);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [expanded, toggleExpanded] = useToggle(true);

  const handleOpenAddDrawer = () => {
    setSelectedDataSource(null);
    toggleDrawer(true);
  };

  const handleOpenEditDrawer = (dataSource) => {
    setSelectedDataSource(dataSource);
    toggleDrawer(true);
  };

  const handleCloseDrawer = () => {
    setSelectedDataSource(null);
    toggleDrawer(false);
  };

  const handleSaveDataSource = (dataSourceData) => {
    if (selectedDataSource) {
      // Update existing data source
      updateCustomDataSourceMutation.mutate(dataSourceData, {
        onSuccess: () => {
          toast.success('Custom data source updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating custom data source:', error);
          toast.error('Failed to update custom data source.', toastConfig);
        },
      });
    } else {
      // Create new data source
      createCustomDataSourceMutation.mutate(dataSourceData, {
        onSuccess: () => {
          toast.success('Custom data source created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating custom data source:', error);
          toast.error('Failed to create custom data source.', toastConfig);
        },
      });
    }
  };

  const handleDeleteDataSource = (id) => {
    if (window.confirm('Are you sure you want to archive this Custom Data Source?')) {
      deleteCustomDataSourceMutation.mutate(id, {
        onSuccess: () => {
          toast.success('Custom data source archived successfully.', toastConfig);
        },
        onError: (error) => {
          console.error('Error archiving custom data source:', error);
          toast.error('Failed to archive custom data source.', toastConfig);
        },
      });
    }
  };

  if (isError) {
    return <div className="error-message">Error loading Custom Data Sources.</div>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <StylishNewButton onClick={handleOpenAddDrawer}>
          <MdAdd className="me-2" />
          Add Custom Data Source
        </StylishNewButton>
      </div>
      {isLoading ? (
        <div>Loading Custom Data Sources...</div>
      ) : (
        <EntityList
          entities={customDataSources}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          renderEntity={(dataSource) => (
            <div
              key={dataSource.id}
              className="d-flex justify-content-between align-items-center p-2 border-bottom"
            >
              <div>
                <strong>{dataSource.name}</strong>
                <div>{dataSource.description}</div>
              </div>
              <div>
                <StylishNewButton
                  className="btn-icon me-2"
                  onClick={() => handleOpenEditDrawer(dataSource)}
                >
                  <MdEdit />
                </StylishNewButton>
                <StylishNewButton
                  className="btn-icon"
                  onClick={() => handleDeleteDataSource(dataSource.id)}
                >
                  <MdDelete />
                </StylishNewButton>
              </div>
            </div>
          )}
        />
      )}

      {/* Add/Edit Drawer */}
      {drawerOpen && (
        <CustomDataSourceEditDrawer
          show={drawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveDataSource}
          dataSource={selectedDataSource}
        />
      )}
    </div>
  );
};

export default CustomDataSourceManager;

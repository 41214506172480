// DraggableSection.js

import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { MdDelete } from 'react-icons/md';

const ITEM_TYPE = 'SECTION';

const DraggableSection = ({
  section,
  index,
  moveSection,
  removeSection,
  foundSection,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { id: section, index },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0.5 : 1;

  // Attach drag and drop refs
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{
        padding: '8px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: '#f9f9f9',
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'black',
        opacity,
        cursor: isDragging ? 'grabbing' : 'grab', // Cursor change
      }}
    >
      <span>{foundSection ? foundSection.name : 'Unknown Section'}</span>
      <StylishNewButton
        size="sm"
        variant="danger"
        onClick={() => removeSection(index)}
      >
        <MdDelete />
      </StylishNewButton>
    </div>
  );
};

export default DraggableSection;

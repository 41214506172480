// CustomDataSourceManagerDrawer.js

import React from 'react';
import DrawerWrapper from 'components/IAP/DrawerWrapper';
import CustomDataSourceManager from './CustomDataSourceManager';

const CustomDataSourceManagerDrawer = ({ onHide }) => {
  return (
    <DrawerWrapper toggle={onHide} title="Manage Custom Data Sources">
      <CustomDataSourceManager />
    </DrawerWrapper>
  );
};

export default CustomDataSourceManagerDrawer;

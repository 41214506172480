// SitrepSidebar.js

import React, { useState, useMemo, useEffect } from 'react'; // Added useEffect
import { useDispatch, useSelector } from 'react-redux';
import {
  useSitrepsV2,
  useSitrepV2,
  useInitSitrepV2,
} from './hooks/useSitrepsV2';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import SitrepSidebarItem from './SitrepSidebarItem';
import { selectGroupGuid, selectIncident } from 'slices/commonSelectors'; // Added selectIncident
import IconClose from 'assets/images/icon__times.svg';
import IconArrowLeft from 'assets/images/icon__arrow--left.svg';
import EntityList from 'components/IAP/EntityList';
import { useToggle } from 'react-use';
import Sitrep from './Sitrep';

import { MdAdd } from 'react-icons/md';
import SectionTemplateManager from './SectionTemplateManager';
import SitrepTemplateManager from './SitrepTemplateManager';

import { toast } from 'react-toastify';
import sitrepName from 'components/DisasterGPT/DisasterGPTDataHelpers/sitrepName';
import generateUUID from 'utils/sharedUtils/generateUUID';

import SitrepCreateDrawer from './SitrepCreateDrawer'; // Import the new drawer component

// Added imports
import { useSharepointFiles, useSyncSharepointFiles } from './hooks/useSharepointFiles';
import { useSharepointFileRefs } from './hooks/useSharepointFileRefs';
import * as dayjs from 'dayjs';

const SitrepSidebar = ({
  toggleSitrepOpen,
  sitrepOpen,
  onClose,
  selectedDatetime,
  map,
  toggleDGPTOpen,
}) => {
  const dispatch = useDispatch();
  const group_guid = useSelector(selectGroupGuid);
  const incident = useSelector(selectIncident); // Added

  const { data: sitreps = [], isLoading, isError } = useSitrepsV2();

  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [expanded, toggleExpanded] = useToggle(true);
  const [selectedSitrepId, setSelectedSitrepId] = useState(null);
  const [filterText, setFilterText] = useState('');

  const {
    data: selectedSitrepData,
    isLoading: isSitrepLoading,
    isError: isSitrepError,
  } = useSitrepV2(selectedSitrepId);

  const initSitrepMutation = useInitSitrepV2();

  const [currentView, setCurrentView] = useState('list');

  const [showCreateDrawer, setShowCreateDrawer] = useState(false);

  // Added state variables
  const [isOutOfSync, setIsOutOfSync] = useState(false); // State to manage sync status
  const { data: files = [], isLoading: isLoadingSharepointFiles } = useSharepointFiles(); // Fetch SharePoint files
  const [isClickedFileSync, setIsClickedFileSync] = useState(false);
  const { data: sharepointFileRefs, isFetching: isFetchingFileRefs } = useSharepointFileRefs(group_guid);
  const { syncSharepointFiles, sharepointSyncSession, isSyncing } = useSyncSharepointFiles();

  useEffect(() => {
    if (sharepointFileRefs && sharepointFileRefs.length > 0) {
      const outOfSyncFiles = sharepointFileRefs.filter((file) => {
        const timestamp = dayjs(file.timestamp);
        return dayjs().diff(timestamp, 'hour') >= 24;
      });
      setIsOutOfSync(outOfSyncFiles.length > 0);
    }
  }, [sharepointFileRefs]);

  const onFileSync = () => {
    syncSharepointFiles(true);
    setIsClickedFileSync(true);
  }

  const handleCreateSitrep = async (formData) => {
    try {
      const sitrepIdResult = await initSitrepMutation.mutateAsync({
        sitrep_template_id: formData.sitrep_template_id || null,
        sitrep_source: 'MANUAL',
        sitrep_source_id: generateUUID(),
        name: formData.name || sitrepName('MANUAL'),
        selectedDatetime: new Date().toISOString(),
      });
      setSelectedSitrepId(sitrepIdResult);
      setShowSidebarSecondary(true);
      setShowCreateDrawer(false); // Close the drawer after creation
    } catch (e) {
      toast.error('Error creating SITREP: ' + e.message);
    }
  };

  const handleSitrepClick = (sitrep) => {
    setSelectedSitrepId(sitrep.id);
    setShowSidebarSecondary(true);
  };

  const filteredSitreps = useMemo(() => {
    const lowerCaseFilter = filterText.toLowerCase();
    if (lowerCaseFilter) {
      return sitreps.filter((sitrep) => {
        if (typeof sitrep.name === 'string') {
          return sitrep.name.toLowerCase().includes(lowerCaseFilter);
        } else {
          console.warn('Encountered sitrep with invalid name:', sitrep);
          return false;
        }
      });
    } else {
      return sitreps;
    }
  }, [sitreps, filterText]);

  if (isError) {
    return (
      <div className="sidebar-content-wide">
        <div className="sidebar-title">
          <h4 className="m-0">SITREPS</h4>
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={onClose}
          >
            <img src={IconClose} alt="Close" />
          </StylishNewButton>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <span>Error loading sitreps.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {currentView !== 'list' || showSidebarSecondary ? (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              if (showSidebarSecondary) {
                setShowSidebarSecondary(false);
                setSelectedSitrepId(null);
              } else {
                setCurrentView('list');
              }
            }}
          >
            <img src={IconArrowLeft} alt="Back" />
          </StylishNewButton>
        ) : null}
        <h4 className="m-0">
          {currentView === 'list'
            ? 'SITREPS'
            : currentView === 'sectionTemplates'
            ? 'Section Templates'
            : currentView === 'sitrepTemplates'
            ? 'SITREP Templates'
            : 'SITREPS'}
        </h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={onClose}
        >
          <img src={IconClose} alt="Close" />
        </StylishNewButton>
      </div>
      {!showSidebarSecondary && (
        <>
          <div
            className="new-chat-icon"
            style={{ display: 'flex', gap: '8px', margin: '8px' }}
          >
            <StylishNewButton
              className="button button--link"
              onClick={() => setShowCreateDrawer(true)}
            >
              <MdAdd className="me-2" /> New SITREP
            </StylishNewButton>
            <StylishNewButton
              className="button button--link"
              onClick={() => setCurrentView('sitrepTemplates')}
            >
              SITREP Templates
            </StylishNewButton>
            <StylishNewButton
              className="button button--link"
              onClick={() => setCurrentView('sectionTemplates')}
            >
              Section Templates
            </StylishNewButton>
            {/* Added File Sync Button */}
            <StylishNewButton
              className="button--primary"
              onClick={onFileSync}
              disabled={isSyncing}
            >
              {isSyncing ? <i className="fa fa-spinner fa-pulse"></i> : isOutOfSync ? 'File Sync!' : 'File Sync'}
            </StylishNewButton>
          </div>
          <hr className="dashed w-10 thin m-0" />
        </>
      )}

      {/* Drawer for Creating SITREP */}
      {showCreateDrawer && (
        <SitrepCreateDrawer
          onHide={() => setShowCreateDrawer(false)}
          onCreate={handleCreateSitrep}
        />
      )}

      <div
        style={{ flexGrow: 1, overflowY: 'auto', height: 'calc(100% - 64px)' }}
      >
        {currentView === 'sectionTemplates' ? (
          <SectionTemplateManager />
        ) : currentView === 'sitrepTemplates' ? (
          <SitrepTemplateManager />
        ) : showSidebarSecondary ? (
          isSitrepLoading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>Loading SITREP details...</span>
            </div>
          ) : isSitrepError ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>Error loading SITREP details.</span>
            </div>
          ) : selectedSitrepData ? (
            <div className="sitrep-wrap" style={{ margin: '16px' }}>
              <Sitrep
                sitrep={selectedSitrepData}
                map={map}
                toggleDGPTOpen={toggleDGPTOpen}
                name={selectedSitrepData.name}
                selectedDatetime={selectedDatetime}
              />
            </div>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <span>No SITREP data available.</span>
            </div>
          )
        ) : isLoading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <div style={{ padding: '8px 16px' }}>
              <input
                type="text"
                placeholder="Filter SITREPs by name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
              />
            </div>
            {filteredSitreps.length > 0 ? (
              <EntityList
                entities={filteredSitreps}
                expanded={expanded}
                toggleExpanded={toggleExpanded}
                header={<></>}
                renderEntity={(sitrep) => (
                  <SitrepSidebarItem
                    key={sitrep.id}
                    sitrep={sitrep}
                    onClick={() => handleSitrepClick(sitrep)}
                    isSelected={selectedSitrepId === sitrep.id}
                  />
                )}
              />
            ) : (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <span>No SITREPs found.</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SitrepSidebar;

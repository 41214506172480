import { PratusTab } from '../DesignSystems/PratusTab/PratusTab';
import { useState } from 'react';
import {
  selectGroupGuid,
  selectIncident,
  selectUser,
  useAppSelector,
} from '../../slices/commonSelectors';
import { exportPaginatedIAP } from './utils/formUtils';
import FormExportCard from './FormExportCard';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { Card } from 'react-bootstrap';

const DashboardSidebar: React.FC = () => {
  const currentIncident = useAppSelector(selectIncident);
  const group_guid = useAppSelector(selectGroupGuid);
  const user = useAppSelector(selectUser);
  const [activeTab, setActiveTab] = useState(0);

  const handleExportPaginatedIAP = () => {
    exportPaginatedIAP({ incident: currentIncident, user, group_guid });
  };

  // Array of form names and labels
  const forms = [
    { formName: 'ICS_202_CG', label: 'ICS 202-CG' },
    { formName: 'ICS_202A_CG', label: 'ICS 202A-CG' },
    { formName: 'ICS_202B_CG', label: 'ICS 202B-CG' },
    { formName: 'ICS_203_CG', label: 'ICS 203-CG' },
    { formName: 'ICS_204_CG', label: 'ICS 204-CG' },
    { formName: 'ICS_205_CG', label: 'ICS 205-CG' },
    { formName: 'ICS_205A_CG', label: 'ICS 205A-CG' },
    { formName: 'ICS_206_CG', label: 'ICS 206-CG' },
    { formName: 'ICS_207_CG', label: 'ICS 207-CG' },
    // { formName: 'ICS_208_CG', label: 'ICS 208-CG' } // Temporarily hiding 208 while it gets fixed properly
  ];

  return (
    <>
      <PratusTab
        tabs={[{ id: 0, label: 'ICS Forms' }]}
        activeTabId={activeTab}
        onTabclick={(id: number) => setActiveTab(id)}
      />

      {activeTab === 0 && (
        <>
          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>IAP</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={handleExportPaginatedIAP}
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <FormExportCard
            key="ICS_201-CG"
            formName="ICS_201-CG"
            label="ICS 201-CG"
            canShareWithTeams={!!currentIncident?.sharepoint_location}
          />

          {forms.map(({ formName, label }) => (
            <FormExportCard
              key={formName}
              formName={formName}
              label={label}
              canShareWithTeams={false}
            />
          ))}
        </>
      )}
    </>
  );
};

export default DashboardSidebar;

import React, { useEffect, useState } from 'react';
import {
  fetchAllRolesInGroup,
  fetchRostersForGroups,
  removeUserFromGroup,
  unassignRole,
} from '../../actions/roleActions';
import { useDispatch, useSelector } from 'react-redux';
import isUserOnlyAdminInGroup from '../Roles/RolesUtils/isUserOnlyAdminInGroup';
import isUserAllowedToBeRemovedFromGroup from '../Roles/RolesUtils/isUserAllowedToBeRemovedFromGroup';
import { useNavigate } from 'react-router-dom';
import { StylishConfirmDialog } from '../DesignSystems/New/StylishConfirmDialog';
import NewRoleAssignmentDialog from '../Roles/NewRoleAssignmentDialog';
import StylishNewTable from '../DesignSystems/New/StylishNewTable';
import { AddMemberDialog } from './AddMemberDialog';
import BulkUserImportDialog from './BulkUserImportDialog';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import { useSubscriptionLevel } from '../SubscriptionManagement/useSubscriptionLevel';
import LockIcon from '../DesignSystems/LockIcon';

// TODO: pagination.
const OrgMembers: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { freemium } = useSubscriptionLevel();

  const [roster, setRoster] = useState<RosterItem[]>([]);
  const [stagedRoleRemoval, setStagedRoleRemoval] = useState<
    [RosterItem, RoleAssignment] | null
  >(null);
  const [stagedUserRemoval, setStagedUserRemoval] = useState<RosterItem | null>(
    null
  );
  const [addNewRoleUser, setAddNewRoleUser] = useState<string | null>(null);
  const [showAddMemberDialog, setShowAddMemberDialog] = useState<boolean>(
    false
  );
  const [showBulkImportDialog, setShowBulkImportDialog] = useState<boolean>(
    false
  );
  const [editRow, setEditRow] = useState<string | null>(null);

  const selectedGroup = useSelector((state: any) => {
    return state.app.currentlySelectedGroup as Group;
  });
  const { user_guid } = useSelector((state: any) => {
    return state.app.user as User;
  });
  const rosters = useSelector((state: any) => {
    return (state.app.rostersForGroups as RosterItem[]) || [];
  });
  const reduxValidateRBACPermissionForActionResult = useSelector(
    (state: any) => {
      return state.app.validateRBACPermissionForActionResult;
    }
  );

  useEffect(() => {
    if (typeof user_guid !== 'undefined' && selectedGroup) {
      dispatch(fetchRostersForGroups([selectedGroup.group_guid]));
      dispatch(fetchAllRolesInGroup(selectedGroup.group_guid));
    }
  }, [user_guid, selectedGroup]);

  useEffect(() => {
    if (rosters.length > 0) {
      const rosterForCurrentOrg = rosters.filter(
        (member) => member.group_guid === selectedGroup.group_guid
      );
      setRoster(rosterForCurrentOrg);
    }
  }, [rosters, selectedGroup]);

  const removeUserConfirmed = async () => {
    if (!stagedUserRemoval) {
      return;
    }
    await dispatch(
      removeUserFromGroup(stagedUserRemoval.user_guid, selectedGroup.group_guid)
    );
    setStagedUserRemoval(null);
  };

  const removeRoleConfirmed = async () => {
    if (!stagedRoleRemoval) {
      return;
    }
    const [rosterItem, roleAssignment] = stagedRoleRemoval;
    await dispatch(
      unassignRole({
        ...roleAssignment,
        user_guid: rosterItem.user_guid,
      })
    );
    setStagedRoleRemoval(null);
  };

  const userRow =
    !!roster &&
    !!selectedGroup &&
    !!roster.length &&
    roster.find(
      (r) =>
        r.user_guid === user_guid && r.group_guid === selectedGroup.group_guid
    );

  const userIsAdminForGroup =
    (!!userRow &&
      !!userRow?.role_assignments &&
      !!userRow?.role_assignments?.length &&
      userRow.role_assignments?.find((ra: any) => ra.name === 'Group Admin')) ||
    false;

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          className="button button--primary"
          onClick={() => setShowAddMemberDialog(true)}
          disabled={freemium}
        >
          <span>Invite New Members</span>
          {freemium ? <LockIcon /> : null}
        </button>
      </div>

      <StylishNewTable
        keyField={'user_guid'}
        columns={[
          {
            dataField: 'email_address',
            text: 'Member Email',
            sort: true,
            headerAttrs: { title: 'Sort By:' },
            attrs: { title: 'Member Email' },
            formatter: (cell: string, row: RosterItem) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="avatar-initials sml">
                    {row.profile_settings?.userName
                      ?.split(' ')
                      .map((word) => word[0])
                      .join('')
                      .slice(0, 3)
                      .toUpperCase()}
                  </div>
                  <div className="d-flex flex-column ms-3 justify-content-center overflow-auto">
                    {row?.profile_settings?.userName ? (
                      <>
                        <div>{row?.profile_settings?.userName}</div>
                        <div className="text-muted pt-2">
                          {row.email_address}
                        </div>
                      </>
                    ) : (
                      <div>{row.email_address}</div>
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            dataField: 'role_assignments',
            formatter: (cell: RoleAssignment[]) =>
              cell.map((i) => i.name).join(', '),
            text: 'Organization Roles',
            sort: true,
            attrs: { title: 'Organization Roles' },
          },
          {
            dataField: '',
            text: '',
            isDummyField: true,
            headerStyle: { width: '54px' },
            formatter: (cell: any, rosterItem) => {
              return isUserAllowedToBeRemovedFromGroup(
                rosterItem,
                user_guid,
                reduxValidateRBACPermissionForActionResult,
                selectedGroup,
                rosters
              ) ? (
                <div className="d-flex">
                  <button
                    className="button button--icon border-0 fs-6"
                    onClick={() => {
                      setStagedUserRemoval(rosterItem);
                    }}
                  >
                    <i className="material-symbols-outlined tw-hover:text-error">
                      delete
                    </i>
                  </button>
                </div>
              ) : null;
            },
          },
        ]}
        rows={roster}
        expandRow={{
          parentClassName: 'parent-expand-tr',
          className: 'child-expand-tr',
          renderer: (row: RosterItem) => {
            const allowProfileEdit =
              row.user_guid === user_guid &&
              row.role_assignments.some((ra) => ra.name === 'Group Admin') &&
              row.allow_admin_profile_edits;
            return (
              <div className="row">
                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-between border-bottom tw-border-b-neutral-700 tw-mb-3">
                    <strong className="py-1">Contact</strong>
                    {allowProfileEdit ? (
                      <button
                        className="button button--link"
                        onClick={() => navigate(`/profile/${row.user_guid}`)}
                      >
                        Edit
                      </button>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-block mb-0">Email</label>
                    <span className="tw-ps-3 fst-italic">
                      {row.email_address}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-block mb-0">Mobile</label>
                    <span className="tw-ps-3 fst-italic">
                      {row?.profile_settings?.mobile_phone_number}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-block mb-0">Home</label>
                    <span className="tw-ps-3 fst-italic">
                      {row?.profile_settings?.home_phone_number}
                    </span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-block mb-0">Pager</label>
                    <span className="tw-ps-3 fst-italic">
                      {row?.profile_settings?.pager_address}
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center justify-content-between border-bottom tw-border-b-neutral-700 tw-mb-3">
                    <strong className="py-1">Positions and Roles</strong>
                    {reduxValidateRBACPermissionForActionResult[
                      'Assign Member to Role'
                    ] ? (
                      <button
                        className="button button--link"
                        onClick={() => {
                          setEditRow((edit) =>
                            edit === row.user_guid ? null : row.user_guid
                          );
                        }}
                      >
                        Edit
                      </button>
                    ) : null}
                  </div>
                  <div className="d-flex pe-3 flex-wrap align-items-baseline">
                    {row.role_assignments?.map((ra) => {
                      const canRemoveRole =
                        editRow === row.user_guid &&
                        reduxValidateRBACPermissionForActionResult[
                          'Unassign Member from Role'
                        ] &&
                        (!isUserOnlyAdminInGroup(
                          selectedGroup.group_guid,
                          rosters
                        ) ||
                          ra.name !== 'Group Admin');
                      return (
                        <span
                          className="badge me-3 mb-3"
                          key={'GroupMembers-' + ra.name}
                        >
                          {ra.name}
                          {canRemoveRole ? (
                            <button
                              type="button"
                              onClick={() => setStagedRoleRemoval([row, ra])}
                            >
                              <i className="material-symbols-outlined">close</i>
                            </button>
                          ) : null}
                        </span>
                      );
                    })}
                    {editRow === row.user_guid &&
                    !!reduxValidateRBACPermissionForActionResult[
                      'Assign Member to Role'
                    ] ? (
                      <button
                        className="button button--link"
                        onClick={() => setAddNewRoleUser(row.user_guid)}
                      >
                        Add User Role
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="button-group mt-4">
                  {row.allow_admin_profile_edits && userIsAdminForGroup && (
                    <button
                      onClick={() => navigate(`/profile/${row.user_guid}`)}
                      className="button button--link"
                    >
                      Edit Profile
                    </button>
                  )}
                </div>
              </div>
            );
          },
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: ({ isAnyExpands }) =>
            isAnyExpands ? <ExpandDownArrow /> : <ExpandRightArrow />,
          expandColumnRenderer: ({ expanded }) =>
            expanded ? <ExpandDownArrow /> : <ExpandRightArrow />,
        }}
      />
      {stagedUserRemoval !== null ? (
        <StylishConfirmDialog
          show={true}
          dialogTitle="Delete Confirmation"
          dialogContent={`Remove ${stagedUserRemoval.email_address} from this group?`}
          onClose={() => setStagedUserRemoval(null)}
          onConfirm={() => removeUserConfirmed()}
        />
      ) : null}
      {stagedRoleRemoval !== null ? (
        <StylishConfirmDialog
          show={true}
          dialogTitle="Delete Confirmation"
          dialogContent={`Remove ${stagedRoleRemoval[0].email_address} from the role ${stagedRoleRemoval[1].name}?`}
          onClose={() => setStagedRoleRemoval(null)}
          onConfirm={() => removeRoleConfirmed()}
        />
      ) : null}
      {addNewRoleUser !== null ? (
        <NewRoleAssignmentDialog
          show={true}
          onClose={() => setAddNewRoleUser(null)}
          group_guid={selectedGroup.group_guid}
          assignee_guid={addNewRoleUser}
        />
      ) : null}
      {showAddMemberDialog ? (
        <AddMemberDialog
          show={true}
          onClose={() => setShowAddMemberDialog(false)}
          onBulkImportClick={() => {
            setShowAddMemberDialog(false);
            setShowBulkImportDialog(true);
          }}
        />
      ) : null}
      {showBulkImportDialog ? (
        <BulkUserImportDialog
          show={true}
          onClose={() => setShowBulkImportDialog(false)}
        />
      ) : null}
    </>
  );
};
export default OrgMembers;

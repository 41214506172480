// hooks/useCustomDataSources.js

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';
import { selectGroupGuid } from 'slices/commonSelectors';

export const keys = {
  customDataSources: 'customDataSources',
};

// Fetch custom data sources
export const useCustomDataSources = () => {
  const group_guid = useSelector(selectGroupGuid);

  return useQuery({
    queryKey: [keys.customDataSources, group_guid],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.customDataSources}/`, {
        params: { group_guid },
      });
      return response.data;
    },
    onError: (error) => {
      console.error('Error fetching custom data sources:', error);
      toast.error('Failed to fetch custom data sources.', { ...toastConfig, autoClose: false });
    },
  });
};

// Create a custom data source
export const useCreateCustomDataSource = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(`${config.apiGateway.customDataSources}/`, {
        ...data,
        group_guid,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.customDataSources]);
    },
    onError: (error) => {
      console.error('Error creating custom data source:', error);
      toast.error('Failed to create custom data source.', toastConfig);
    },
  });
};

// Update a custom data source
export const useUpdateCustomDataSource = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      const response = await axios.put(`${config.apiGateway.customDataSources}/${id}`, rest);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.customDataSources]);
    },
    onError: (error) => {
      console.error('Error updating custom data source:', error);
      toast.error('Failed to update custom data source.', toastConfig);
    },
  });
};

// Archive a custom data source
export const useDeleteCustomDataSource = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(`${config.apiGateway.customDataSources}/${id}`);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.customDataSources]);
    },
    onError: (error) => {
      console.error('Error archiving custom data source:', error);
      toast.error('Failed to archive custom data source.', toastConfig);
    },
  });
};

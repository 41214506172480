// SitrepCreateDrawer.js

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import { useSitrepTemplates } from './hooks/useSitrepTemplates';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect'; // Ensure correct path

const SitrepCreateDrawer = ({ onHide, onCreate }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { name: '', sitrep_template_id: '' },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = methods;

  const {
    data: sitrepTemplates = [],
    isLoading: isTemplatesLoading,
  } = useSitrepTemplates();

  // Prepare options for StylishNewSelect
  const sitrepOptions = sitrepTemplates.map((template) => ({
    value: template.id,
    label: template.name,
  }));

  return (
    <DrawerWrapper toggle={onHide} title="Create New SITREP">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onCreate)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <div className="drawer-body">
            <Row className="mb-3">
              <FormTextField
                title="SITREP Name"
                fieldName="name"
                register={methods.register}
                rules={{ required: 'SITREP name is required' }}
                error={errors.name}
              />
            </Row>
            <Row className="mb-3">
              <Col>
                <label htmlFor="sitrep_template_id">
                  Select SITREP Template
                </label>
                {isTemplatesLoading ? (
                  <div>Loading templates...</div>
                ) : (
                  <Controller
                    name="sitrep_template_id"
                    control={control}
                    rules={{ required: 'Please select a SITREP template' }}
                    render={({ field }) => (
                      <StylishNewSelect
                        {...field}
                        options={sitrepOptions}
                        isClearable
                        placeholder="Select a SITREP Template"
                        onChange={(selectedOption) => {
                          field.onChange(
                            selectedOption ? selectedOption.value : ''
                          );
                        }}
                        value={
                          sitrepOptions.find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        styles={{
                          control: (base, state) => ({
                            ...base,
                            borderColor: errors.sitrep_template_id
                              ? 'red'
                              : base.borderColor,
                          }),
                        }}
                      />
                    )}
                  />
                )}
                {errors.sitrep_template_id && (
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errors.sitrep_template_id.message}
                  </span>
                )}
              </Col>
            </Row>
          </div>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton variant="secondary" onClick={onHide}>
                Cancel
              </StylishNewButton>
              <StylishNewButton
                variant="primary"
                type="submit"
                disabled={!isValid}
              >
                Create
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default SitrepCreateDrawer;

// useDisasterChatsHooks.js

import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { pollDChat, postDChat } from 'slices/dchatSlice';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { selectUserGuid, selectGroupGuid, selectIncident, useAppSelector } from '../../../slices/commonSelectors';


export const keys = {
    base: 'disasterChats',
    sitreps: 'sitreps'
};

// Hook to fetch sitreps (list of sitreps)
export const useSitreps = () => {
  const group_guid = useSelector(selectGroupGuid);

  return useQuery({
    queryKey: [keys.sitreps, group_guid],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.sitreps}/`, {
        params: { group_guid },
      });
      return response.data;
    },
    staleTime: 0,
    onError: (error) => {
      toast.error('Failed to fetch sitreps.', { ...toastConfig, autoClose: false });
    },
  });
};

// Hook to fetch a specific sitrep by ID with polling until status is 'Summarized'
export const useSitrep = (sitrepId) => {
  return useQuery({
    queryKey: [keys.sitreps, sitrepId],
    queryFn: async () => {
      const response = await axios.post(config.apiGateway.fetchSitreps, {
        sitrepId,
      });
      let sitrep = response.data;
      if (Array.isArray(sitrep) && sitrep.length > 0) {
        sitrep = sitrep[0];
      }
      // Check if sitrep status is 'Error', and throw an error if so
      if (sitrep.status && sitrep.status.toLowerCase() === 'error') {
        throw new Error('Sitrep processing failed with status "Error"');
      }
      return sitrep;
    },
    enabled: Boolean(sitrepId), // Only run the query if sitrepId is truthy
    refetchInterval: (data) => {
      let status
      if(data && !!data.queryKey)
      {
        status = data?.state?.data?.status.toLowerCase()
      }
      else
      {
        status = data?.status.toLowerCase()
      }
      if (status !== 'summarized') {
        return 500; // Continue polling every 500ms
      } else {
        return false; // Stop polling
      }
    },
    retry: false, // Do not retry the query if it fails
    onError: (error) => {
      console.error('Error fetching sitrep:', error);
      toast.error('Failed to fetch sitrep.', { ...toastConfig, autoClose: false });
    },
  });
};


// Hook to initiate a sitrep
export const useInitSitrep = () => {
  const queryClient = useQueryClient();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async ({ chatDGPTSession, source, source_id, name, selectedDatetime }) => {
      const response = await axios.post(config.apiGateway.initSitrep, {
        chatDGPTSession,
        source,
        source_id,
        group_guid,
        user_guid,
        name,
        selectedDatetime,
      });

      if (response.data) {
        return response.data; // Assuming response.data is the sitrepId
      } else {
        throw new Error('Invalid response from server: sitrepId not found.');
      }
    },
    onSuccess: (sitrepId) => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error initiating sitrep:', error);
      toast.error('Failed to initiate sitrep.', toastConfig);
    },
  });
};



// Hook to update a sitrep (e.g., rename or archive)
export const useUpdateSitrep = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ name, sitrep_id, archived }) => {
      const payload = { id: sitrep_id };
      if (name !== undefined) payload.name = name;
      if (archived !== undefined) payload.archived = archived;

      const response = await axios.put(`${config.apiGateway.sitreps}/`, payload);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error updating sitrep:', error);
      toast.error('Failed to update sitrep.', toastConfig);
    },
  });
};

// Hook to refresh a sitrep
export const useRefreshSitrep = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, name, selectedDatetime, sections = [] }) => {
      const response = await axios.post(config.apiGateway.refreshSitrep, {
        id,
        user_guid,
        group_guid,
        name,
        selectedDatetime,
        sections,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error refreshing sitrep:', error);
      toast.error('Failed to refresh sitrep.', toastConfig);
    },
  });
};


// Hook to update a sitrep section
export const useUpdateSitrepSection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionId, sectionText, sectionTitle }) => {
      const response = await axios.post(config.apiGateway.updateSitrepSection, {
        sitrepId,
        sectionId,
        sectionText,
        sectionTitle,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error updating sitrep section:', error);
      toast.error('Failed to update sitrep section.', toastConfig);
    },
  });
};

// Hook to add a sitrep section
export const useAddSitrepSection = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionId, sectionTitle, selectedDatetime }) => {
      const response = await axios.post(config.apiGateway.addSitrepSection, {
        sitrepId,
        sectionId,
        sectionTitle,
        user_guid,
        group_guid,
        selectedDatetime,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error adding sitrep section:', error);
      toast.error('Failed to add sitrep section.', toastConfig);
    },
  });
};

// Hook to remove a sitrep section
export const useRemoveSitrepSection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionId }) => {
      const response = await axios.post(config.apiGateway.removeSitrepSection, {
        sitrepId,
        sectionId,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error removing sitrep section:', error);
      toast.error('Failed to remove sitrep section.', toastConfig);
    },
  });
};


export const useDisasterChats = () => {
    const user_guid = useSelector(selectUserGuid);
    const incident = useAppSelector(selectIncident);

    return useQuery({
        queryKey: [keys.base, incident?.id],
        queryFn: async () => {
            const response = await axios.get(
                `${config.apiGateway.disasterchat}/`, 
                { params: { user_guid, incident_id: incident?.id } }
            );
            return response.data;
        },
        staleTime: 0,  // Ensure the query refetches immediately after invalidation
        onSuccess: (data) => {
            // Handle success if needed
        },
        onError: (error) => {
            toast.error("Failed to fetch disaster chats.", { ...toastConfig, autoClose: false });
        },
    });
};

export const useCreateDisasterChat = () => {
    const queryClient = useQueryClient();
    const user_guid = useSelector(selectUserGuid);
    const group_guid = useSelector(selectGroupGuid);
    const current_sitrep_id = useSelector((state) => state.dchat.current_sitrep_id);
    


    return useMutation({
        mutationFn: async (data) => {
            const payload = {
                ...data,
                user_guid,
                current_sitrep_id,
                group_guid,
                incident,
            };
            return await axios.post(`${config.apiGateway.disasterchat}`, payload);
        },
        onMutate: () => {
            // Optional: Optimistically update the cache or UI here
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
        onError: (error) => {
            toast.error(error.message, { ...toastConfig, autoClose: false });
        },
    });
};

export const useUpdateDisasterChat = () => {
    const queryClient = useQueryClient();
    const user_guid = useSelector(selectUserGuid);
    const group_guid = useSelector(selectGroupGuid);
    const current_sitrep_id = useSelector((state) => state.dchat.current_sitrep_id);
    const incident = useAppSelector(selectIncident);

    return useMutation({
        mutationFn: async (data) => {
            const payload = {
                ...data,
                user_guid,
                current_sitrep_id,
                group_guid,
                incident,
            };
            return await axios.put(`${config.apiGateway.disasterchat}`, payload);
        },
        onMutate: () => {
            // Optional: Optimistically update the cache or UI here
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
        onError: (error) => {
            toast.error(error.message, { ...toastConfig, autoClose: false });
        },
    });
};

export const useDeleteDisasterChat = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (dchat_id) => {
            return await axios.delete(`${config.apiGateway.disasterchat}`, {
                data: { dchat_id }
            });
        },
        onMutate: () => {
            // Optional: Optimistically update the cache or UI here
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
        onError: (error) => {
            toast.error(error.message, { ...toastConfig, autoClose: false });
        },
    });
};

export const useCancelDisasterChat = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data) => {
            return await axios.post(`${config.apiGateway.disasterchat}/cancel`, data);
        },
        onMutate: () => {
            // Optional: Optimistically update the cache or UI here
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: [keys.base] });
        },
        onError: (error) => {
            toast.error(error.message, { ...toastConfig, autoClose: false });
        },
    });
};

export const usePollDChat = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (dchatId) => {
    dispatch(pollDChat(dchatId));
    // Invalidate queries immediately after dispatch
    queryClient.invalidateQueries({ queryKey: [keys.base] });
  };
};


export const usePostDChat = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const incident = useAppSelector(selectIncident);

  return async (data) => {
    try {
      // Dispatch the Redux action
      await dispatch(postDChat({ ...data, incident }));
      
      // Invalidate the queries to refresh the chats list
      queryClient.invalidateQueries({ queryKey: [keys.base] });
    } catch (error) {
      toast.error(error.message, { ...toastConfig, autoClose: false });
    }
  };
};



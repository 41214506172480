import { productionEnv } from './debug';

const apiPrefix = productionEnv
  ? window.env.COMSDOMAIN
  : process.env.REACT_APP_COMSDOMAIN;

export default {
  apiGateway: {
    getUserById: `${apiPrefix}/dice/getUserById`,
    tempGetStoryIncidents: `${apiPrefix}/dice/tempGetStoryIncidents`,
    createOrGetUser: `${apiPrefix}/dice/createOrGetUser`,
    fetchGroupsForUser: `${apiPrefix}/dice/getGroupsForUser`,
    createGroup: `${apiPrefix}/dice/createGroup`,
    fetchIncidents: `${apiPrefix}/dice/getIncidentsForUser`,
    fetchIncidentStatus: `${apiPrefix}/dice/getIncidentStatus`,
    updateRole: `${apiPrefix}/dice/updateGroupMemberRole`,
    fetchGroupSolicitations: `${apiPrefix}/dice/getGroupSolicitations`,
    fetchUserSubscriptions: `${apiPrefix}/dice/getUserSubscriptions`,
    fetchSeatAssignments: `${apiPrefix}/dice/getSeatAssignments`,
    assignSeat: `${apiPrefix}/dice/assignSeat`,
    unassignSeat: `${apiPrefix}/dice/unassignSeat`,
    updateSubscriptionPlan: `${apiPrefix}/dice/updateSubscriptionPlan`,
    fetchAssignableDiceUsers: `${apiPrefix}/dice/fetchAssignableDiceUsers`,
    inviteGroupMember: `${apiPrefix}/dice/inviteToGroup`,
    createIncident: `${apiPrefix}/dice/createIncident`,
    updateIncident: `${apiPrefix}/dice/updateIncident/`,
    createDashboard: `${apiPrefix}/dice/createDashboard`,
    updateDashboard: `${apiPrefix}/dice/updateDashboard`,
    fetchDashboards: `${apiPrefix}/dice/fetchDashboards`,
    fetchDashboardData: `${apiPrefix}/dice/fetchDashboardData`,
    fetchDashboardDataForWidget: `${apiPrefix}/dice/fetchDashboardDataForWidget`,
    createWidget: `${apiPrefix}/dice/createWidget`,
    fetchODDSData: `${apiPrefix}/odds/datasets/data/`,
    updateLayouts: `${apiPrefix}/dice/updateLayouts`,
    toggleWidget: `${apiPrefix}/dice/toggleWidget`,
    updateWidget: `${apiPrefix}/dice/updateWidget`,
    removeWidget: `${apiPrefix}/dice/removeWidget`,
    updateIncidentGroup: `${apiPrefix}/dice/updateIncidentGroup`,
    fetchODDSMetadatasets: `${apiPrefix}/odds/fetchODDSMetadatasets`,
    sendNotificationByGroup: `${apiPrefix}/dice/notifications/sendNotificationByGroup`,
    fetchNotificationsByGroup: `${apiPrefix}/dice/notifications/fetchNotificationsByGroup`,
    deleteNotification: `${apiPrefix}/dice/notifications/deleteNotification`,
    fetchAllNotificationLogSummaries: `${apiPrefix}/dice/notifications/fetchAllNotificationLogSummaries`,
    upsertNotification: `${apiPrefix}/dice/notifications/upsertNotification`,
    getRosterForIncident: `${apiPrefix}/dice/incident/getRosterForIncident`,
    createRoleExecutiveSummary: `${apiPrefix}/dice/executive_summary/createRoleExecutiveSummary`,
    fetchAllRolesInGroup: `${apiPrefix}/dice/roles/allRolesForGroup`,
    fetchAllRoleAssignmentsInGroup: `${apiPrefix}/dice/roles/allRolesPerUserInGroup`,
    fetchRoleActionsLog: `${apiPrefix}/dice/roles/fetchRoleActionLog`,
    createRole: `${apiPrefix}/dice/roles/createRoleWithPermissions`,
    editRole: `${apiPrefix}/dice/roles/editRoleAndPermissionsAndTags`,
    deleteRole: `${apiPrefix}/dice/roles/deleteRole`,
    assignRole: `${apiPrefix}/dice/roles/assignUserToRole`,
    unassignRole: `${apiPrefix}/dice/roles/unAssignUserFromRole`,
    fetchRostersForGroups: `${apiPrefix}/dice/roles/fetchRostersForGroups`,
    fetchAllPermissions: `${apiPrefix}/dice/roles/permissions`,
    fetchAllPermissionsInRole: `${apiPrefix}/dice/roles/getPermissionsForRole`,
    editGroup: `${apiPrefix}/dice/editgroup`,
    validateRBACPermissionForAction: `${apiPrefix}/dice/validations/validateRBACPermissionForAction`,
    validateUserInGroups: `${apiPrefix}/dice/validations/validateUserInGroups`,
    validateUserInGroupWithRole: `${apiPrefix}/dice/validations/validateUserInGroupWithRole`,
    validateUserSubscription: `${apiPrefix}/dice/validations/validateUserSubscription`,
    validateUserInIncident: `${apiPrefix}/dice/validations/validateUserInIncident`,
    validateUserIncidentAdmin: `${apiPrefix}/dice/validations/validateUserIncidentAdmin`,
    validateUserSubscriptionActionByMinimumPlanIdRequired: `${apiPrefix}/dice/validations/validateUserSubscriptionActionByMinimumPlanIdRequired`,
    addUserToIncidentByAdmin: `${apiPrefix}/dice/addUserToIncidentByAdmin`,
    editIncidentMemberByAdmin: `${apiPrefix}/dice/incident/updateIncidentMember`,
    removeUserFromIncidentByAdmin: `${apiPrefix}/dice/incident/removeIncidentMember`,
    removeSelfFromIncident: `${apiPrefix}/dice/incident/removeSelfFromIncident`,
    fetchPods: `${apiPrefix}/dice/pods/fetchPods`,
    createPod: `${apiPrefix}/dice/pods/createPod`,
    editPod: `${apiPrefix}/dice/pods/editPod`,
    deletePod: `${apiPrefix}/dice/pods/deletePod`,
    fetchPodNTKL: `${apiPrefix}/dice/pods/getNeedToKnowListForPod`,
    addToPodNTKL: `${apiPrefix}/dice/pods/addUserToNeedToKnowList`,
    removeFromPodNTKL: `${apiPrefix}/dice/pods/removeUserFromNeedToKnowList`,
    createPodData: `${apiPrefix}/dice/pods/addDataToPod`,
    editPodData: `${apiPrefix}/dice/pods/editDataInPod`,
    deletePodData: `${apiPrefix}/dice/pods/deleteDataFromPod`,
    fetchPodsDatasets: `${apiPrefix}/dice/pods/fetchPodsDatasets`,
    fetchPodDatasetForDashboard: `${apiPrefix}/dice/pods/fetchPodDatasetForDashboard`,
    fetchOddsDatasetForDashboard: `${apiPrefix}/dice/odds/fetchOddsDatasetForDashboard`,
    validateUserInPodSecurityPolicy: `${apiPrefix}/dice/validations/validateUserInPodSecurityPolicy`,
    removeUserFromGroup: `${apiPrefix}/dice/removeUserFromGroup`,
    deleteGroup: `${apiPrefix}/dice/deleteGroup`,
    fetchRolesByIncident: `${apiPrefix}/dice/roles/allRolesForIncident`,
    getLivestreamDataJson: `${apiPrefix}/dice/getLivestreamDataJson`, 
    exportMarkdownToDocument: `${apiPrefix}/dice/exports/exportMarkdownToDocument`,
    fetchDatasetMetadataForWidget: `${apiPrefix}/dice/data/fetchDatasetMetadataForWidget`,
    updateCurrentlySelected: `${apiPrefix}/dice/updateCurrentlySelected`,
    fetchAssetRisks: `${apiPrefix}/dice/assets/fetchAssetRisks`,
    fetchFormRecords: `${apiPrefix}/dice/forms/fetchFormRecords`,
    upsertFormRecord: `${apiPrefix}/dice/forms/upsertFormRecord`,
    deleteFormRecord: `${apiPrefix}/dice/forms/deleteFormRecord`,
    exportFormToPDF: `${apiPrefix}/dice/forms/exportFormToPDF`,
    fetchSignatures: `${apiPrefix}/dice/signatures/fetchSignatures`,
    upsertSignature: `${apiPrefix}/dice/signatures/upsertSignature`,
    deleteSignature: `${apiPrefix}/dice/forms/deleteSignature`,
    fetchIncidentsForGroup: `${apiPrefix}/dice/fetchIncidentsForGroup`,
    fetchWorkflowNodes: `${apiPrefix}/dice/workflows/fetchWorkflowNodes`,
    fetchWorkflows: `${apiPrefix}/dice/workflows/fetchWorkflows`,
    upsertWorkflow: `${apiPrefix}/dice/workflows/upsertWorkflow`,
    deleteWorkflow: `${apiPrefix}/dice/workflows/deleteWorkflow`,
    executeWorkflow: `${apiPrefix}/dice/workflows/executeWorkflow`,
    rollbackWorkflow: `${apiPrefix}/dice/workflows/rollbackWorkflow`,
    updateUserProfileSettings: `${apiPrefix}/dice/users/updateUserProfileSettings`,
    bulkUserImport: `${apiPrefix}/dice/bulkUserImport`,
    setCloneDefaults: `${apiPrefix}/dice/setCloneDefaults`,
    fetchCloneDefaults: `${apiPrefix}/dice/fetchCloneDefaults`,
    tileServerProxy: `${apiPrefix}/tiles`,
    fetchDICELog: `${apiPrefix}/dice/fetchDICELog`,
    fetchNotificationLog: `${apiPrefix}/dice/notifications/fetchNotificationLog`,
    fetchNotificationLogInstance: `${apiPrefix}/dice/notifications/fetchNotificationLogInstance`,
    getPodDatasetBounds: `${apiPrefix}/dice/pods/getPodDatasetBounds`,
    getOpenDatasetBounds: `${apiPrefix}/dice/odds/getOpenDatasetBounds`,
    downloadFormsDataArray: `${apiPrefix}/dice/forms/downloadFormsDataArray`,
    toggleAllowAdminProfileEdit: `${apiPrefix}/dice/users/toggleAllowAdminProfileEdit`,
    confirmUserNotNew: `${apiPrefix}/dice/users/confirmUserNotNew`,
    fetchUserGroupsRolesIncidents: `${apiPrefix}/dice/groups/fetchUserGroupsRolesIncidents`,
    deleteLayout: `${apiPrefix}/dice/deleteLayout`,
    sendFakeError: `${apiPrefix}/dice/validations/sendFakeError`,
    createDistributionList: `${apiPrefix}/dice/distribution-list/createDistributionList`,
    fetchDistributionListByGroup: `${apiPrefix}/dice/distribution-list/fetchDistributionListByGroup`,
    deleteDistributionList: `${apiPrefix}/dice/distribution-list/deleteDistributionList`,
    removeMembersFromDistributionList: `${apiPrefix}/dice/distribution-list/removeMembersFromDistributionList`,
    fetchAI2FPData: `${apiPrefix}/dice/AI2FP/fetchAI2FPData`,
    fetchCoreComponents: `${apiPrefix}/dice/AI2FP/fetchCoreComponents`,
    upsertCoreComponent: `${apiPrefix}/dice/AI2FP/upsertCoreComponent`,
    linkCoreComponents: `${apiPrefix}/dice/AI2FP/linkCoreComponents`,
    unlinkCoreComponents: `${apiPrefix}/dice/AI2FP/unlinkCoreComponents`,
    deleteCoreComponent: `${apiPrefix}/dice/AI2FP/deleteCoreComponent`,
    CCTempTiles: `${apiPrefix}/dice/AI2FP/CCTempTiles`,
    fetchThresholdsForAOR: `${apiPrefix}/dice/AI2FP/fetchThresholdsForAOR`,
    upsertThreshold: `${apiPrefix}/dice/AI2FP/upsertThreshold`,
    addUserToIncident: `${apiPrefix}/dice/incident/createIncidentMembers`,
    addNonMemberToIncident: `${apiPrefix}/dice/incident/createIncidentNonMembers`,
    getIncidentLogs: `${apiPrefix}/dice/incident/getIncidentLogs`,
    markIncidentMemberActive: `${apiPrefix}/dice/incident/markIncidentMemberActive`,
    markIncidentMemberDeactive: `${apiPrefix}/dice/incident/markIncidentMemberDeactive`,
    incidentMemberWorkLogs: `${apiPrefix}/dice/incident/incidentMemberWorkLogs`,
    updateIncidentMemberWorkLog: `${apiPrefix}/dice/incident/updateIncidentMemberWorkLog`,
    addObjectiveToIncident: `${apiPrefix}/dice/incident/addObjectiveToIncident`,
    updateIncidentObjectiveSelection: `${apiPrefix}/dice/incident/updateIncidentObjectiveSelection`,
    updateIncidentActionSelection: `${apiPrefix}/dice/incident/updateIncidentActionSelection`,
    updateIncidentResourceSelection: `${apiPrefix}/dice/incident/updateIncidentResourceSelection`,
    getAllObjectiveToIncident: `${apiPrefix}/dice/incident/getAllObjectiveToIncident`,
    deleteObjectiveToIncident: `${apiPrefix}/dice/incident/deleteObjectiveToIncident`,
    updateObjectiveToIncident: `${apiPrefix}/dice/incident/updateObjectiveToIncident`,
    addResourcesToIncident: `${apiPrefix}/dice/incident/addResourcesToIncident`,
    getAllResourcesToIncident: `${apiPrefix}/dice/incident/getAllResourcesToIncident`,
    deleteResourcesToIncident: `${apiPrefix}/dice/incident/deleteResourcesToIncident`,
    updateResourcesToIncident: `${apiPrefix}/dice/incident/updateResourcesToIncident`,
    getAllResourcesToGroup: `${apiPrefix}/dice/incident/getAllResourcesToGroup`,
    getAllObjectivesToGroup: `${apiPrefix}/dice/incident/getAllObjectivesToGroup`,
    addScheduleToIncident: `${apiPrefix}/dice/incident/addScheduleToIncident`,
    getAllScheduleToIncident: `${apiPrefix}/dice/incident/getAllScheduleToIncident`,
    getAllUserScheduleToIncident: `${apiPrefix}/dice/incident/getAllUserScheduleToIncident`,
    deleteScheduleToIncident: `${apiPrefix}/dice/incident/deleteScheduleToIncident`,
    upsertOperationalPeriod: `${apiPrefix}/dice/incident/upsertOperationalPeriod`,
    setIncidentActiveStatus: `${apiPrefix}/dice/incident/setIncidentActiveStatus`,
    setBriefingSketch: `${apiPrefix}/dice/incident/setBriefingSketch`,
    updateBriefingSituation: `${apiPrefix}/dice/incident/updateBriefingSituation`,
    fetchBriefing: `${apiPrefix}/dice/incident/fetchBriefing`,
    createGroupQuestion: `${apiPrefix}/dice/groups/createGroupQuestion`,
    getAllGroupQuestion: `${apiPrefix}/dice/groups/getAllGroupQuestion`,
    deleteGroupQuestion: `${apiPrefix}/dice/groups/deleteGroupQuestion`,
    getAllGroupQuestionWithAnswer: `${apiPrefix}/dice/groups/getAllGroupQuestionWithAnswer`,
    addUserAnswerForGroupQuestion: `${apiPrefix}/dice/groups/addUserAnswerForGroupQuestion`,
    getAllUserSchedule: `${apiPrefix}/dice/user/getAllUserSchedule`,
    addUserSchedule: `${apiPrefix}/dice/user/addUserSchedule`,
    deleteUserSchedule: `${apiPrefix}/dice/user/deleteUserSchedule`,
    getAllGroupAnswer: `${apiPrefix}/dice/groups/getAllGroupAnswer`,
    uploadFile: `${apiPrefix}/dice/upload/uploadFile`,
    uploadImageToBlobStorage: `${apiPrefix}/dice/upload/uploadImageToBlobStorage`,
    generateSignedUrl: `${apiPrefix}/dice/upload/generateSignedUrl`,
    fetchGroupImages: `${apiPrefix}/dice/upload/fetchGroupImages`,
    fetchAnomalies: `${apiPrefix}/dice/AI2FP/fetchAnomalies`,
    archiveAnomaly: `${apiPrefix}/dice/AI2FP/archiveAnomaly`,
    anomalyToIncident: `${apiPrefix}/dice/AI2FP/anomalyToIncident`,
    fetchADLs: `${apiPrefix}/dice/alerts/fetchADLs`,
    upsertADL: `${apiPrefix}/dice/alerts/upsertADL`,
    deleteADL: `${apiPrefix}/dice/alerts/deleteADL`,
    assignADL: `${apiPrefix}/dice/alerts/assignADL`,
    unassignADL: `${apiPrefix}/dice/alerts/unassignADL`,
    fetchHVAs: `${apiPrefix}/dice/alerts/fetchHVAs`,
    fetchHVAData: `${apiPrefix}/dice/alerts/fetchHVAData`,
    upsertHVA: `${apiPrefix}/dice/alerts/upsertHVA`,
    deleteHVA: `${apiPrefix}/dice/alerts/deleteHVA`,
    fetchHVATemplateCategories: `${apiPrefix}/dice/alerts/fetchHVATemplateCategories`,
    upsertHVATemplateCategory: `${apiPrefix}/dice/alerts/upsertHVATemplateCategory`,
    deleteHVATemplateCategory: `${apiPrefix}/dice/alerts/deleteHVATemplateCategory`,
    fetchHVATemplateRisks: `${apiPrefix}/dice/alerts/fetchHVATemplateRisks`,
    upsertHVATemplateRisk: `${apiPrefix}/dice/alerts/upsertHVATemplateRisk`,
    deleteHVATemplateRisk: `${apiPrefix}/dice/alerts/deleteHVATemplateRisk`,
    fetchHVATemplateColumns: `${apiPrefix}/dice/alerts/fetchHVATemplateColumns`,
    cloneHVA: `${apiPrefix}/dice/alerts/cloneHVA`,
    fetchAlerts: `${apiPrefix}/dice/alerts/fetchAlerts`,
    updateAlert: `${apiPrefix}/dice/alerts/updateAlert`,
    fetchGeolocationAtRiskToOrg: `${apiPrefix}/dice/alerts/geolocationsAtRiskToOrg`,
    addNewAction: `${apiPrefix}/dice/action/addActionToGroup`,
    getAllActionToGroup: `${apiPrefix}/dice/action/getAllActionToGroup`,
    deleteActionToGroup: `${apiPrefix}/dice/action/deleteActionToGroup`,
    updateActionToGroup: `${apiPrefix}/dice/action/updateActionToGroup`,
    getAllActionToIncident: `${apiPrefix}/dice/action/getAllActionToIncident`,
    addInitialIncidentReportToIncident: `${apiPrefix}/dice/report/addInitialIncidentReportToIncident`,
    getAllInitialIncidentReportToIncident: `${apiPrefix}/dice/report/getAllInitialIncidentReportToIncident`,
    deleteInitialIncidentReportToIncident: `${apiPrefix}/dice/report/deleteInitialIncidentReportToIncident`,
    escalateIncidentReport: `${apiPrefix}/dice/report/escalateIncidentReport`,
    deleteFile: `${apiPrefix}/dice/upload/deleteFile`,
    crateInitialIncidentReportComment: `${apiPrefix}/dice/report/crateInitialIncidentReportComment`,
    getAllInitialIncidentReportLogs: `${apiPrefix}/dice/report/getAllInitialIncidentReportLogs`,
    getAllInitialIncidentReportRecipientList: `${apiPrefix}/dice/report/getAllInitialIncidentReportRecipientList`,
    updateInitialIncidentReportRecipientList: `${apiPrefix}/dice/report/updateInitialIncidentReportRecipientList`,
    trackUserLogin: `${apiPrefix}/dice/tracking/trackUserLogin`,
    getEnvironmentVariables: `${apiPrefix}/dice/getEnvironmentVariables`,
    getAllWorkspace: `${apiPrefix}/dice/groups/getAllWorkspace`,
    addUpdateInitialIncidentReportChecklist: `${apiPrefix}/dice/report/addUpdateInitialIncidentReportChecklist`,
    getAllInitialIncidentReportChecklist: `${apiPrefix}/dice/report/getAllInitialIncidentReportChecklist`,
    deleteInitialIncidentReportChecklist: `${apiPrefix}/dice/report/deleteInitialIncidentReportChecklist`,
    getChecklistFormAssetAndIncidentType: `${apiPrefix}/dice/report/getChecklistFormAssetAndIncidentType`,
    upsertInitialIncidentReportTeamChecklist: `${apiPrefix}/dice/report/upsertInitialIncidentReportTeamChecklist`,
    getAllInitialIncidentReportTeamChecklist: `${apiPrefix}/dice/report/getAllInitialIncidentReportTeamChecklist`,
    deleteInitialIncidentReportTeamChecklist: `${apiPrefix}/dice/report/deleteInitialIncidentReportTeamChecklist`,
    getTeamChecklistFormAssetAndIncidentType: `${apiPrefix}/dice/report/getTeamChecklistFormAssetAndIncidentType`,
    getInitialIncidentReportById: `${apiPrefix}/dice/report/getInitialIncidentReportById`,
    getAllGroupLocationEntity: `${apiPrefix}/dice/groups/location/getAllGroupLocationEntity`,
    getAllGroupLocationSubEntity: `${apiPrefix}/dice/groups/location/getAllGroupLocationSubEntity`,
    getAllGroupLocationEnterprice: `${apiPrefix}/dice/groups/location/getAllGroupLocationEnterprice`,
    getAllGroupLocationAsset: `${apiPrefix}/dice/groups/location/getAllGroupLocationAsset`,
    getAllGroupLocation: `${apiPrefix}/dice/groups/location/getAllGroupLocation`,
    fetchNestedCategories: `${apiPrefix}/dice/groups/location/fetchNestedCategories`,
    fetchNestedCategoryItems: `${apiPrefix}/dice/groups/location/fetchNestedCategoryItems`,
    upsertNestedCategoryItem: `${apiPrefix}/dice/groups/location/upsertNestedCategoryItem`,
    upsertNestedCategory: `${apiPrefix}/dice/groups/location/upsertNestedCategory`,
    deleteNestedCategoryItem: `${apiPrefix}/dice/groups/location/deleteNestedCategoryItem`,
    deleteNestedCategory: `${apiPrefix}/dice/groups/location/deleteNestedCategory`,
    upsertGroupLocationEntity: `${apiPrefix}/dice/groups/location/upsertGroupLocationEntity`,
    upsertGroupLocationSubEntity: `${apiPrefix}/dice/groups/location/upsertGroupLocationSubEntity`,
    upsertGroupLocationEnterprice: `${apiPrefix}/dice/groups/location/upsertGroupLocationEnterprice`,
    upsertGroupLocationAsset: `${apiPrefix}/dice/groups/location/upsertGroupLocationAsset`,
    deleteGroupLocationEntity: `${apiPrefix}/dice/groups/location/deleteGroupLocationEntity`,
    deleteGroupLocationSubEntity: `${apiPrefix}/dice/groups/location/deleteGroupLocationSubEntity`,
    deleteGroupLocationEnterprice: `${apiPrefix}/dice/groups/location/deleteGroupLocationEnterprice`,
    deleteGroupLocationAsset: `${apiPrefix}/dice/groups/location/deleteGroupLocationAsset`,
    upsertGroupLocation: `${apiPrefix}/dice/groups/location/upsertGroupLocation`,
    upsertBranch: `${apiPrefix}/dice/forms/ics/planning_worksheet/upsertBranch`,
    deleteBranch: `${apiPrefix}/dice/forms/ics/planning_worksheet/deleteBranch`,
    upsertDivision: `${apiPrefix}/dice/forms/ics/planning_worksheet/upsertDivision`,
    deleteDivision: `${apiPrefix}/dice/forms/ics/planning_worksheet/deleteDivision`,
    getAllBranch: `${apiPrefix}/dice/forms/ics/planning_worksheet/getAllBranch`,
    getAllDivision: `${apiPrefix}/dice/forms/ics/planning_worksheet/getAllDivision`,
    getAllDivisionByGroupId: `${apiPrefix}/dice/forms/ics/planning_worksheet/getAllDivisionByGroupId`,
    upsertPlanningWorkSheets: `${apiPrefix}/dice/forms/ics/planning_worksheet/upsertPlanningWorkSheets`,
    getPlanningWorksheet: `${apiPrefix}/dice/forms/ics/planning_worksheet/getPlanningWorksheet`,
    getAllWorkAssignments: `${apiPrefix}/dice/forms/ics/planning_worksheet/getAllWorkAssignments`,
    markWorkAssignmentWithPlanningWorksheet: `${apiPrefix}/dice/forms/ics/planning_worksheet/markWorkAssignmentWithPlanningWorksheet`,
    unmarkWorkAssignmentWithPlanningWorksheet: `${apiPrefix}/dice/forms/ics/planning_worksheet/unmarkWorkAssignmentWithPlanningWorksheet`,
    upsertPlanningWorksheetCommunication: `${apiPrefix}/dice/forms/ics/planning_worksheet/upsertPlanningWorksheetCommunication`,
    deletePlanningWorksheetCommunication: `${apiPrefix}/dice/forms/ics/planning_worksheet/deletePlanningWorksheetCommunication`,
    deleteAssignmentResource: `${apiPrefix}/dice/forms/ics/planning_worksheet/deleteAssignmentResource`,
    updateAssignmentResource: `${apiPrefix}/dice/forms/ics/planning_worksheet/updateAssignmentResource`,
    updateWorkAssignmentStatus: `${apiPrefix}/dice/forms/ics/planning_worksheet/updateWorkAssignmentStatus`,
    fetchMedicalPlanData: `${apiPrefix}/dice/forms/ics/medical_plan/fetchMedicalPlan`,
    createMedicalPlanData: `${apiPrefix}/dice/forms/ics/medical_plan/createMedicalPlan`,
    fetchMedicalAidStationList: `${apiPrefix}/dice/forms/ics/medical_plan/fetchMedicalAidStationList`,
    createMedicalPlanMedicalAid: `${apiPrefix}/dice/forms/ics/medical_plan/createMedicalPlanMedicalAid`,
    createMedicalAidStation: `${apiPrefix}/dice/forms/ics/medical_plan/createMedicalAidStation`,
    deleteMedicalAidStation: `${apiPrefix}/dice/forms/ics/medical_plan/deleteMedicalAidStation`,
    deleteMedicalPlanMedicalAidStation: `${apiPrefix}/dice/forms/ics/medical_plan/deleteMedicalPlanMedicalAidStation`,
    createTransportation: `${apiPrefix}/dice/forms/ics/medical_plan/createTransportation`,
    fetchTransportationList: `${apiPrefix}/dice/forms/ics/medical_plan/fetchTransportationList`,
    createMedicalPlanTransportation: `${apiPrefix}/dice/forms/ics/medical_plan/createMedicalPlanTransportation`,
    deleteTransportation: `${apiPrefix}/dice/forms/ics/medical_plan/deleteTransportation`,
    deleteMedicalPlanTransportation: `${apiPrefix}/dice/forms/ics/medical_plan/deleteMedicalPlanTransportation`,
    fetchHospitalList: `${apiPrefix}/dice/forms/ics/medical_plan/fetchHospitalList`,
    createHospital: `${apiPrefix}/dice/forms/ics/medical_plan/createHospital`,
    createMedicalPlanHospital: `${apiPrefix}/dice/forms/ics/medical_plan/createMedicalPlanHospital`,
    upsertICSObjectiveCommandEmphasis: `${apiPrefix}/dice/forms/ics/objectives_plan/upsertICSObjectiveCommandEmphasis`,
    getICSObjectiveCommandEmphasis: `${apiPrefix}/dice/forms/ics/objectives_plan/getICSObjectiveCommandEmphasis`,
    upsertIapCoverSheetToOperationalPeriod: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/upsertIapCoverSheetToOperationalPeriod`,
    getIapCoverSheet: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/getIapCoverSheet`,
    upsertIapCoverSheetAttachment: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/upsertIapCoverSheetAttachment`,
    deleteIapCoverSheetAttachment: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/deleteIapCoverSheetAttachment`,
    upsertIapCoverSheetApprovedByIC: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/upsertIapCoverSheetApprovedByIC`,
    deleteIapCoverSheetApprovedByIC: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/deleteIapCoverSheetApprovedByIC`,
    upsertSafetyAndHealthForm: `${apiPrefix}/dice/forms/ics/safety_and_health/upsertSafetyAndHealthForm`,
    getAllSafetyAndHealthFormByOPId: `${apiPrefix}/dice/forms/ics/safety_and_health/getAllSafetyAndHealthFormByOPId`,
    deleteSafetyAndHealthForm: `${apiPrefix}/dice/forms/ics/safety_and_health/deleteSafetyAndHealthForm`,

    upsertCommunication: `${apiPrefix}/dice/forms/ics/planning_worksheet/upsertCommunication`,
    fetchCommunication: `${apiPrefix}/dice/forms/ics/planning_worksheet/fetchCommunication`,
    deleteHospital: `${apiPrefix}/dice/forms/ics/medical_plan/deleteHospital`,
    deleteMedicalPlanHospital: `${apiPrefix}/dice/forms/ics/medical_plan/deleteMedicalPlanHospital`,
    fetchResourceRequest: `${apiPrefix}/dice/forms/ics/ics213rr/fetchResourceRequest`,
    createResourceRequest: `${apiPrefix}/dice/forms/ics/ics213rr/createResourceRequest`,
    createUpdateOrder: `${apiPrefix}/dice/forms/ics/ics213rr/createUpdateOrder`,
    deleteOrder: `${apiPrefix}/dice/forms/ics/ics213rr/deleteOrder`,
    fetchAllResourceRequest: `${apiPrefix}/dice/forms/ics/ics213rr/fetchAllResourceRequest`,
    deleteResourceRequest: `${apiPrefix}/dice/forms/ics/ics213rr/deleteResourceRequest`,
    upsertIapChecklistAttachment: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/upsertIapChecklistAttachment`,
    deleteIapChecklistAttachment: `${apiPrefix}/dice/forms/ics/iap_cover_sheet/deleteIapChecklistAttachment`,
    upsertMeeting: `${apiPrefix}/dice/forms/ics/meeting/upsertMeeting`,
    fetchMeetings: `${apiPrefix}/dice/forms/ics/meeting/fetchMeetings`,
    diceActivityLogs: `${apiPrefix}/dice/report/getAllLogs`,
    testAlertEmail: `${apiPrefix}/dice/alerts/geolocationsAtRiskReport`,
    upsertAor: `${apiPrefix}/dice/groups/location/upsetAOR`,
    getAllAor: `${apiPrefix}/dice/groups/location/getAllAOR`,
    deleteAor: `${apiPrefix}/dice/groups/location/deleteAOR`,
    getAllUserWorkspace: `${apiPrefix}/dice/groups/getAllUserWorkspace`,
    getAllUserWithSubscription: `${apiPrefix}/dice/getAllUser`,
    upgradeUserSubscription: `${apiPrefix}/dice/upgradeSubscription`,
    bulkUpgradeUserSubscription: `${apiPrefix}/dice/bulkUpgradeSubscription`,
    getAllQuestionByAor: `${apiPrefix}/dice/report/getAllQuestionByAor`,
    upsertQuestionByAor: `${apiPrefix}/dice/report/upsertQuestionByAor`,
    fetchTwitterData: `${apiPrefix}/dice/osint/fetchTwitterData`,
    fetchOSINTAsk: `${apiPrefix}/dice/osint/fetchOSINTAsk`,
    fetchTaskList: `${apiPrefix}/dice/taskmanagement/searchTask`,
    saveTask: `${apiPrefix}/dice/taskmanagement/saveTask`,
    generateTeamSite: `${apiPrefix}/dice/teams/createTeams`,
    uploadFileToTeams: `${apiPrefix}/dice/teams/uploadFileToTeams`,
    shareFileWithTeams: `${apiPrefix}/dice/teams/shareFileWithTeams`,
    addTaskComment: `${apiPrefix}/dice/taskmanagement/upsertCommentForTask`,
    getAllTaskComments: `${apiPrefix}/dice/taskmanagement/getAllCommentForTask`,
    getAllTaskLogs: `${apiPrefix}/dice/taskmanagement/getAllLogsTask`,
    stripeCreateSession: `${apiPrefix}/dice/stripe/stripeCreateSession`,
    createStripeSubscription: `${apiPrefix}/dice/subscriptions/createStripeSubscription`,
    updateStripeSubscriptionStatus: `${apiPrefix}/dice/subscriptions/updateStripeSubscriptionStatus`,
    fetchAssignableUsersByGroup: `${apiPrefix}/dice/subscriptions/fetchAssignableUsersByGroup`,
    assignStripeSeats: `${apiPrefix}/dice/subscriptions/assignStripeSeats`,
    unassignStripeSeats: `${apiPrefix}/dice/subscriptions/unassignStripeSeats`,
    fetchUserSubscriptionInfo: `${apiPrefix}/dice/subscriptions/fetchUserSubscriptionInfo`,
    getAllTaskUnits: `${apiPrefix}/dice/taskmanagement/getAllTaskUnits`,
    upsertTaskUnit: `${apiPrefix}/dice/taskmanagement/upsertTaskUnit`,
    deleteTaskUnit: `${apiPrefix}/dice/taskmanagement/deleteTaskUnit`,
    teamTemplateIds: `${apiPrefix}/dice/teams/templateIds`,
    addMemberToTeams: `${apiPrefix}/dice/teams/addMemberToTeams`,
    removeMemberFromTeams:`${apiPrefix}/dice/teams/removeMemberFromTeams`,
    checkTeamsStatus:`${apiPrefix}/dice/teams/checkTeamsStatus`,
    getGroupFromIncidentId: `${apiPrefix}/dice/incident/getGroupFromIncidentId`,
    fetchAIEventAnalysis: `${apiPrefix}/dice/dgpt/fetchAIEventAnalysis`,

    // AI
    exportSitrepToPdf: `${apiPrefix}/dice/dgpt/exportSitrepToPdf`,
    exportAsForm: `${apiPrefix}/dice/forms/exportAsForm`,
    exportPaginatedIAP: `${apiPrefix}/dice/forms/exportPaginatedIAP`,
    initSitrep: `${apiPrefix}/dice/dgpt/initSitrep`,
    fetchSitreps: `${apiPrefix}/dice/dgpt/fetchSitreps`,
    refreshSitrep: `${apiPrefix}/dice/dgpt/refreshSitrep`,
    updateSitrepSection: `${apiPrefix}/dice/dgpt/updateSitrepSection`,
    addSitrepSection: `${apiPrefix}/dice/dgpt/addSitrepSection`,
    removeSitrepSection: `${apiPrefix}/dice/dgpt/removeSitrepSection`,
    fetchGroupAIUsage: `${apiPrefix}/dice/dgpt/fetchGroupAIUsage`,
    fetchAllGroupsAIUsage: `${apiPrefix}/dice/dgpt/fetchAllGroupsAIUsage`,
    fetchSOPs: `${apiPrefix}/dice/dgpt/sop/files`,
    uploadSOP: `${apiPrefix}/dice/dgpt/sop/upload`,
    deleteSOP: `${apiPrefix}/dice/dgpt/sop/delete`,
    deletedSOPFiles: `${apiPrefix}/dice/dgpt/sop/deletedfiles`,
    downloadSOP: `${apiPrefix}/dice/dgpt/sop/download`,
    initPlanAISuggestion: `${apiPrefix}/dice/dgpt/planai/init`,
    fetchPlanAISuggestion: `${apiPrefix}/dice/dgpt/planai/fetch`,
    bulkAddObjectives: `${apiPrefix}/dice/incident/bulkAddObjectives`,
    bulkAddActions: `${apiPrefix}/dice/incident/bulkAddActions`,
    bulkAddResources: `${apiPrefix}/dice/incident/bulkAddResources`,
    disasterchat: `${apiPrefix}/dice/dgpt/disasterchat`,
    sitreps: `${apiPrefix}/dice/dgpt/sitreps`,
    sitrepTemplates: `${apiPrefix}/dice/dgpt/sitreps/templates`,
    sitrepSectionTemplates: `${apiPrefix}/dice/dgpt/sitreps/section-templates`,
    sitrepsV2: `${apiPrefix}/dice/dgpt/sitreps_v2`,
    customDataSources: `${apiPrefix}/dice/dgpt/custom-data-sources`,

    // ICS
    ics_incident: `${apiPrefix}/dice/ics/incident`,
    ics_incidents: `${apiPrefix}/dice/ics/incidents`,
    ics_periods: `${apiPrefix}/dice/ics/periods`,

    ics_incident_info: `${apiPrefix}/dice/ics/incident_info`,
    ics_map_sketch: `${apiPrefix}/dice/ics/map_sketch`,
    ics_objectives: `${apiPrefix}/dice/ics/objectives`,
    ics_incident_objectives: `${apiPrefix}/dice/ics/objectives/incident`,
    ics_strategies: `${apiPrefix}/dice/ics/strategies`,
    ics_incident_strategies: `${apiPrefix}/dice/ics/strategies/incident`,
    ics_tactics: `${apiPrefix}/dice/ics/tactics`,
    ics_incident_tactics: `${apiPrefix}/dice/ics/tactics/incident`,
    ics_actions: `${apiPrefix}/dice/ics/actions`,
    ics_incident_actions: `${apiPrefix}/dice/ics/actions/incident`,

    ics_medical_aid_stations: `${apiPrefix}/dice/ics/medical_aid_stations`,
    ics_incident_medical_aid_stations: `${apiPrefix}/dice/ics/medical_aid_stations/incident`,
    ics_medical_transportation: `${apiPrefix}/dice/ics/medical_transportation`,
    ics_incident_medical_transportation: `${apiPrefix}/dice/ics/medical_transportation/incident`,
    ics_hospitals: `${apiPrefix}/dice/ics/hospitals`,
    ics_incident_hospitals: `${apiPrefix}/dice/ics/hospitals/incident`,

    ics_work_assignments: `${apiPrefix}/dice/ics/work_assignments/incident`,
    ics_coms: `${apiPrefix}/dice/ics/coms`,
    ics_incident_coms: `${apiPrefix}/dice/ics/coms/incident`,

    ics_incident_resources: `${apiPrefix}/dice/ics/resources/incident`,
    ics_resource_requests: `${apiPrefix}/dice/ics/resource_requests`,
    ics_resource_request_items: `${apiPrefix}/dice/ics/resource_request_items`,

    ics_resource_sets: `${apiPrefix}/dice/ics/resource_sets`,
    ics_resource_sites: `${apiPrefix}/dice/ics/resource_sites`,
    ics_strike_teams: `${apiPrefix}/dice/ics/strike_teams`,
    ics_custom_resource_types: `${apiPrefix}/dice/ics/custom_resources`,

    redisHealthCheck: `${apiPrefix}/dice/notification/redisHealthCheck`,
    notification_jobs: `${apiPrefix}/dice/notification/jobs`,

    // /ICS

    custom_events: `${apiPrefix}/dice/custom_events`,
    prompt_library: `${apiPrefix}/dice/prompt_library`,
    sharepoint_files: `${apiPrefix}/dice/dgpt/sharepoint_index`,
    sharepoint_file_vs_refs: `${apiPrefix}/dice/dgpt/sharepoint_file_vs_refs`,
    sharepoint_sync: `${apiPrefix}/dice/dgpt/sharepoint_sync`,
  },
  apiPrefix,
} as const;





// DataSources.js

import React, { useState, useEffect } from 'react';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useSitrepsV2 } from './hooks/useSitrepsV2';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect'; // Ensure correct path
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './CustomAccordion.css';
import { useCustomDataSources } from './hooks/useCustomDataSources';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import CustomDataSourceManagerDrawer from './CustomDataSourceManagerDrawer'; // Import the new drawer component

const LocationRequiredDataSources = [
  { source: 'Current Weather', description: 'LOCATION REQUIRED (Event).  Current weather at location.', id: '' },
  { source: 'Forecast Weather', description: 'LOCATION REQUIRED (Event).  Forecast weather at location.', id: '' },
  { source: 'Power Outages', description: 'LOCATION REQUIRED (Event).  Power outages at location.', id: '' },
];

const DataSources = ({ selections = [], setSelections, usingSitrep = false }) => {
  const { data: sitreps = [], refetch } = useSitrepsV2();

  const currentSitrepId = useSelector((state) => state.dchat.current_sitrep_id);

  const [dataSources, setDataSources] = useState([]);

  const isUsingTeams =
    sessionStorage['isUsingTeams'] === true ||
    sessionStorage['isUsingTeams'] === 'true';


  const DChatDataSources = [
    { source: 'ICS-Active', description: 'Current ICS data. Quick and concise.', id: '' },
    { source: 'ICS-Historic', description: 'Full version history of ICS data for current incident. Expect longer run times. Precise prompts are advised.', id: '' },
    
  ]

  const { data: customDataSources = [] } = useCustomDataSources();
  const [showCustomDataManager, setShowCustomDataManager] = useState(false);

  useEffect(() => {
    let fetchedDataSources = [
      { source: 'SITREP', description: 'Situation Reports', id: '' },
      { source: 'Files', description: `Files uploaded to Pratus${isUsingTeams ? ' and SharePoint' : ''}`, id: '' },
      { source: 'Web', description: 'Web Scraping', id: '' },
      { source: 'News', description: 'News Articles', id: '' },
      { source: 'Social Media', description: 'Social Media Posts', id: '' },
    ];

    if (usingSitrep) {
      fetchedDataSources = fetchedDataSources.filter((source) => source.source !== 'SITREP');
      fetchedDataSources = [...fetchedDataSources, ...LocationRequiredDataSources];
    }
    else
    {
      fetchedDataSources = [...fetchedDataSources,...DChatDataSources]
    }

    // Add custom data sources
    const customSources = customDataSources.map((cd) => ({
      source: cd.name,
      description: cd.description,
      id: cd.id,
      isCustom: true,
      customData: cd,
    }));

    if (usingSitrep) {
      fetchedDataSources = [...fetchedDataSources, ...customSources];
    }

    setDataSources(fetchedDataSources);
  }, [usingSitrep, isUsingTeams, customDataSources]); // Added customDataSources to dependencies

  const sitrepOptions = sitreps
    .filter((s) => !s.archived)
    .map((sitrep) => ({
      value: sitrep.id,
      label: sitrep.name,
    }));

  useEffect(() => {
    if (sitrepOptions.length > 0 && currentSitrepId && !usingSitrep) {
      // Only manage SITREP when not usingSitrep
      const newSelections = selections.map((s) =>
        s.source === 'SITREP' ? { ...s, id: currentSitrepId } : s
      );
      if (!newSelections.find((s) => s.source === 'SITREP')) {
        const newSitrepSelection = { source: 'SITREP', id: currentSitrepId };
        setSelections([...newSelections, newSitrepSelection]);
      } else if (JSON.stringify(newSelections) !== JSON.stringify(selections)) {
        setSelections(newSelections);
      }
    }
  }, [currentSitrepId, sitrepOptions, selections, usingSitrep, setSelections]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSelect = (row, isSelect) => {
    if (isSelect) {
      setSelections([...selections, row]);
    } else {
      if (usingSitrep) {
        setSelections(selections.filter((selection) => selection.source !== row.source));
      } else {
        setSelections(selections.filter((selection) => selection.source !== row.source));
      }
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      if (usingSitrep) {
        setSelections(rows);
      } else {
        setSelections(rows.map((row) => row));
      }
    } else {
      setSelections([]);
    }
  };

  const handleSitrepChange = (selectedOption) => {
    setSelections((prevSelections) => {
      if (usingSitrep) return prevSelections; // SITREP should not be managed when usingSitrep=true

      const newSelections = prevSelections.filter((selection) => selection.source !== 'SITREP');
      if (selectedOption) {
        newSelections.push({ source: 'SITREP', id: selectedOption.value });
      }

      setDataSources((prevDataSources) =>
        prevDataSources.map((source) =>
          source.source === 'SITREP' ? { ...source, id: selectedOption ? selectedOption.value : '' } : source
        )
      );

      return newSelections;
    });
  };

  const selectedSitrep = usingSitrep
    ? null
    : selections.find((selection) => selection.source === 'SITREP');
  const selectedSitrepOption = selectedSitrep
    ? sitrepOptions.find((option) => option.value === selectedSitrep.id)
    : null;

  const columns = [
    {
      dataField: 'source',
      text: 'Source Name',
      sort: true,
      attrs: { title: 'Source Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'data',
      text: 'Data',
      sort: false,
      formatter: (cellContent, row) => {
        if (row.source === 'SITREP' && !usingSitrep) {
          // Only render SITREP selector when not usingSitrep
          if (!sitrepOptions.length) {
            return <div>No SITREPS Available</div>;
          }

          return (
            <StylishNewSelect
              options={sitrepOptions}
              value={selectedSitrepOption || null} // Ensure null is passed if no option is selected
              onChange={handleSitrepChange}
              isClearable={true}
              placeholder="Select a SITREP"
            />
          );
        } else if (row.isCustom) {
          // Optionally, you can provide additional UI for custom data sources
          return (
            <div>
              <em>Custom Data Source</em>
            </div>
          );
        }
        return null; // No additional data for other sources
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    hideSelectAll: false,
    selected: usingSitrep ? selections.map((selection) => selection.source) : selections.map((s) => s.source),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="border-b">Data Sources</Accordion.Header>
        <Accordion.Body>
          <div>
            {usingSitrep && (
              <>
                <div>
                  <StylishNewButton onClick={() => setShowCustomDataManager(true)}>
                    Custom Data
                  </StylishNewButton>
                </div>
                <div>
                  <label>Select data for DisasterChat to consider.</label>
                </div>
                <div>
                  <small> Multiple data sources can be more comprehensive, but takes longer to process.  Fewer or one data source can lead to more focused results.</small>
                </div>
              </>
            ) || (
              <>
                <div>
                  <label>Select data for DisasterChat to consider.</label>
                </div>
                <div>
                  Custom Data is currently only available for SITREPS.  Coming to DisasterChat soon!
                </div>
              </>
            )}
            
            <StylishNewTable
              key={`table-${dataSources.length}-${selections.length}`}
              keyField={'source'}
              rows={dataSources}
              columns={columns}
              selectRow={selectRow}
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {showCustomDataManager && (
        <CustomDataSourceManagerDrawer
          show={showCustomDataManager}
          onHide={() => setShowCustomDataManager(false)}
        />
      )}
    </Accordion>
  );
};

export default DataSources;

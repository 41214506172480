import {
  selectGroups,
  selectUser,
  useAppSelector,
} from '../slices/commonSelectors';

export const useIsDev = () => {
  const groups = useAppSelector(selectGroups);
  const user = useAppSelector(selectUser);

  if (groups && groups.length) {
    return (
      !!groups.find((group) => group.group_name.includes('DisasterTechDev')) ||
      user.email_address.includes('@disastertech.com') ||
      user.email_address.includes('@qn1k.onmicrosoft.com') ||
      user.email_address.includes('@disastertech1.onmicrosoft.com') ||
      user.email_address.includes('@pratushubai.onmicrosoft.com') ||
      user.email_address.includes('@13bl1.onmicrosoft.com')
    );
  } else {
    return false;
  }
};

import React from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  MdCampaign,
  MdChat,
  MdFlashOn,
  MdLocationOn,
  MdMap,
  MdOutlineTask,
  MdOutlineWarning,
  MdSettings,
  MdLibraryBooks,
} from 'react-icons/md';
import { RiFileTextLine, RiOilFill } from 'react-icons/ri';
import { TiLightbulb, TiWeatherStormy } from 'react-icons/ti';
import { useFeatureFlag, useOilSpillFlag } from '../../utils/featureFlags';
import { useWindowSize } from 'react-use';
import { useSubscriptionLevel } from '../SubscriptionManagement/useSubscriptionLevel';

const SIDEBAR_ICONS = [
  { key: 'DisasterChats', icon: <MdChat /> },
  { key: 'SITREPS', icon: <MdLibraryBooks /> },
  { key: 'Settings', icon: <MdSettings /> },
  { key: 'Basemap', icon: <MdMap /> },
  { key: 'Geolocations', icon: <MdLocationOn /> },
  { key: 'Events', icon: <MdOutlineWarning /> },
  { key: 'Weather', icon: <TiWeatherStormy /> },
  { key: 'Impact Models', icon: <TiLightbulb /> },
  { key: 'Incident Report', icon: <RiFileTextLine /> },
  { key: 'Task Management', icon: <MdOutlineTask /> },
  // { key: 'Power Outages', icon: <MdFlashOn /> },
  { key: 'Oil Spill', icon: <RiOilFill /> },
];

type MapSidebarProps = {
  sidebarActiveItem: string;
  sidebarActiveItemHandler: (item: string) => void;
};

const MapSidebar: React.FC<React.PropsWithChildren & MapSidebarProps> = ({
  children,
  sidebarActiveItem,
  sidebarActiveItemHandler,
}) => {
  const streamlineFlag = useFeatureFlag('STREAMLINE_PLATFORM');
  const powerOutageFlag = useFeatureFlag('POWER_OUTAGES');
  const oilSpillFlag = useOilSpillFlag();
  const { freemium } = useSubscriptionLevel();

  const sidebarIcons = SIDEBAR_ICONS.filter((i) =>
    streamlineFlag ? !['Task Management'].includes(i.key) : true
  )
    .filter((i) => (!powerOutageFlag ? i.key !== 'Power Outages' : true))
    .filter((i) => (!oilSpillFlag ? i.key !== 'Oil Spill' : true))
    .filter((i) =>
      freemium
        ? [
            'Settings',
            'Basemap',
            'Geolocations',
            'Events',
            'Weather',
            'Impact Models',
          ].includes(i.key)
        : true
    );

  const windowSize = useWindowSize();
  const disableOnMobile = windowSize.width < 768; // Our sidebars are quite wide, around 650px.

  return (
    <div className="map-sidebar">
      {children}
      <div className="sidebar-icons">
        <ul>
          {sidebarIcons.map((i) => (
            <li key={i.key}>
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    {i.key}
                    {disableOnMobile ? (
                      <>
                        <br />
                        (Screen size too small)
                      </>
                    ) : (
                      ''
                    )}
                  </Tooltip>
                )}
                placement="left"
              >
                <StylishNewButton
                  customButton
                  onClick={() =>
                    !disableOnMobile && sidebarActiveItemHandler(i.key)
                  }
                  className={sidebarActiveItem === i.key ? 'active' : ''}
                  aria-label={i.key}
                  disabled={disableOnMobile}
                >
                  {i.icon}
                </StylishNewButton>
              </OverlayTrigger>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MapSidebar;

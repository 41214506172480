// Sitrep.js

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import Info from 'components/DesignSystems/text/Info';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useNavigate } from 'react-router-dom';
import {
  setCurrentSitrepId,
  setDgptSitrepToInitialIncidentReportSummary,
  setSelectedDChat,
} from 'slices/dchatSlice';
import SitrepSection from './SitrepSection';
import { AiOutlineFileWord } from 'react-icons/ai';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import {
  useAddSitrepSectionV2,
  useRefreshSitrepV2,
  useUpdateSitrepSectionV2,
  useRemoveSitrepSectionV2,
} from 'components/DisasterGPT/hooks/useSitrepsV2';

import { useSectionTemplates } from 'components/DisasterGPT/hooks/useSectionTemplates';

import { docxExport } from './DisasterGPTDataHelpers/DGPTExport';

const Sitrep = ({ sitrep, map, toggleDGPTOpen, name, selectedDatetime }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const divRef = useRef();

  const [createReport, setCreateReport] = useState(false);
  const [showMostRecent, setShowMostRecent] = useState(true);
  const [addingSection, setAddingSection] = useState(false);
  const [addSectionTemplate, setAddSectionTemplate] = useState(null);

  const dgptSitrepToInitialIncidentReport = useSelector(
    (state) => state.dchat.dgptSitrepToInitialIncidentReport
  );

  // Get the mutations
  const refreshSitrepMutation = useRefreshSitrepV2();
  const addSitrepSectionMutation = useAddSitrepSectionV2();
  const updateSitrepSectionMutation = useUpdateSitrepSectionV2();
  const removeSitrepSectionMutation = useRemoveSitrepSectionV2();

  const { data: sectionTemplates, isLoading: isSectionTemplatesLoading } = useSectionTemplates();

  // Determine loading states
  const isRefreshSitrepLoading = refreshSitrepMutation.isLoading;
  const isAddSectionLoading = addSitrepSectionMutation.isLoading;
  const isUpdateSectionLoading = updateSitrepSectionMutation.isLoading;
  const isRemoveSectionLoading = removeSitrepSectionMutation.isLoading;

  // Determine if the sitrep is still processing
  const isSitrepProcessing =
    (sitrep && sitrep.status && sitrep.status.toLowerCase() !== 'summarized') ||
    isRefreshSitrepLoading;

  // Overall actions disabled state
  const isActionsDisabled =
    isSitrepProcessing ||
    isRefreshSitrepLoading ||
    isAddSectionLoading ||
    isUpdateSectionLoading ||
    isRemoveSectionLoading;

  function prepareMessagesForReport(sitrep) {
    let pattern = /【[^】]*】/g;
    const markdownString = sitrep.sections
      .map((s) => {
        let title = s.name;
        let text = s.section_versions[0].sitrep_section_text;

        text = text?.replaceAll('```json', '').replaceAll('```', '');
        if (text && text[0] === `"` && text[text.length - 1] === `"`) {
          text = text.slice(1, -1);
        }

        return `
### ${title}:  

${text}

---
`
          .replaceAll('{{', '')
          .replaceAll('}}', '');
      })
      .map((line) => line.trim())
      .join('\n')
      .replaceAll(pattern, '');
    return markdownString;
  }

  function sendDgptSitrepToInitialIncidentReportSummary() {
    setCreateReport(true);

    const sitrepMarkdown = prepareMessagesForReport(sitrep);

    const canvas = map.current.getCanvas();
    const mapScreenshot = canvas.toDataURL('image/png');

    reduxDispatch(
      setDgptSitrepToInitialIncidentReportSummary({
        sitrep: sitrep,
        sitrep_id: sitrep.id,
        sitrepMarkdown: sitrepMarkdown,
        reference: sitrep.sitrep_source,
        reference_id: sitrep.sitrep_source_id,
        mapScreenshot: mapScreenshot,
      })
    );
  }

  function timestampSelectedCallback() {
    setShowMostRecent(false);
  }

  useEffect(() => {
    if (!!dgptSitrepToInitialIncidentReport && !!createReport) {
      setCreateReport(false);
      navigate(`/report/new`, { replace: true });
    }
  }, [dgptSitrepToInitialIncidentReport, createReport, navigate]);

  function updateSitrep() {
    refreshSitrepMutation.mutate({ id: sitrep.id, selectedDatetime });
  }

  function addSectionClicked() {
    setAddingSection(true);
  }

  useEffect(() => {
    if (addingSection) {
      const { current } = divRef;
      if (current !== null) {
        current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [addingSection]);

  function addSectionSaveClicked() {
    addSitrepSectionMutation.mutate({
      sitrepId: sitrep.id,
      sectionTemplateId: addSectionTemplate.value,
      selectedDatetime: selectedDatetime,
      sitrep_source: sitrep.sitrep_source,
      sitrep_source_id: sitrep.sitrep_source_id,
      order: sitrep.sections.length + 1
    });
    setAddingSection(false);
    setAddSectionTemplate(null);
  }

  function addSectionCancelClicked() {
    setAddingSection(false);
    setAddSectionTemplate(null);
  }

  // Sections are now sorted on the backend, so we use them as they come
  const sortedSections = sitrep && sitrep.sections ? sitrep.sections : [];

  function chatClicked() {
    reduxDispatch(setSelectedDChat({}));
    reduxDispatch(setCurrentSitrepId(sitrep.id));
    toggleDGPTOpen();
  }

  function prepareSitrepForClipboard() {
    return sitrep.sections
      .map((section) => {
        let title = section.name;
        let text = section.section_versions[0].sitrep_section_text;

        return `
**${title}:**  

${text}
`;
      })
      .join('\n');
  }

  function copySitrepToClipboard() {
    const sitrepContent = prepareSitrepForClipboard();
    navigator.clipboard.writeText(sitrepContent).catch((err) => {
      console.error('Failed to copy SITREP: ', err);
    });
  }

  // Function to Handle DOCX Export
  const downloadSitrepDocx = () => {
    // Preprocess sitrep data into the format expected by docxExport
    let messages = sitrep.sections.map((section) => ({
      role: section.name,
      content: section.section_versions[0].sitrep_section_text.replace(/【.*?】/g, '') || '',
      timestamp: section.section_versions[0].timestamp,
    }));

    const headerMessage = {
      role: sitrep.name,
      content: '',
      timestamp: '',
    };

    messages = [headerMessage, ...messages];

    // Call the docxExport function
    docxExport(messages, 'Download', sitrep.name || 'Sitrep');
  };

  // Handlers for updating and removing sections
  const handleUpdateSection = (sectionId, newText) => {
    updateSitrepSectionMutation.mutate({
      sitrepId: sitrep.id,
      sectionId,
      sectionText: newText,
    });
  };

  const handleRemoveSection = (sectionId) => {
    removeSitrepSectionMutation.mutate({
      sitrepId: sitrep.id,
      sectionId,
    });
  };

  return (
    <div className="pb-5">
      <h6 className="mt-1 mb-1">
        DisasterGPT is currently in Preview. For bug reports and feature requests, please contact{' '}
        <a href="mailto:support@disastertech.com">support@disastertech.com</a>
      </h6>

      <div>{!!sitrep?.name && <h5>{sitrep.name}</h5>}</div>

      {/* Show sections as they are available */}
      {sortedSections.map((section, index) => (
        <SitrepSection
          key={'SitrepSection-' + index}
          sitrep={sitrep}
          section={section}
          index={index}
          totalSections={sortedSections.length}
          timestampSelectedCallback={timestampSelectedCallback}
          showMostRecent={showMostRecent}
          isSitrepReady={!isSitrepProcessing}
          selectedDatetime={selectedDatetime}
          onUpdateSection={handleUpdateSection}
          onRemoveSection={handleRemoveSection}
        />
      ))}

      {/* Show loading spinner if sitrep is processing */}
      {isSitrepProcessing && (
        <div className="pb-5 text-center">
          <span>DisasterGPT is analyzing...</span>
          <div>
            <i className="fa fa-spinner fa-pulse fs-6"></i>
          </div>
          <Info>AutoSITREP can take between 10-60 seconds to complete.</Info>
        </div>
      )}

      {/* Add Section Button */}
      {!!addingSection ? (
        <div className="SitrepSection mb-5" ref={divRef}>
          <div className="paragraph-header">
            <h4>Add Section</h4>
          </div>
          <div className="SitrepSection-text">
            {isSectionTemplatesLoading ? (
              <div className="d-flex justify-content-center">
                <i className="fa fa-spinner fa-pulse fs-5 "></i>
              </div>
            ) : (
              <StylishNewSelect
                options={sectionTemplates.map((template) => ({
                  value: template.id,
                  label: template.name,
                }))}
                value={addSectionTemplate}
                onChange={(selectedOption) => setAddSectionTemplate(selectedOption)}
                placeholder={'Select a section template'}
                isDisabled={isActionsDisabled}
              />
            )}
            <div className="mt-2 d-flex justify-content-end p-3">
              <StylishNewButton
                className="button--secondary me-3"
                onClick={() => addSectionCancelClicked()}
              >
                Cancel
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                onClick={() => addSectionSaveClicked()}
                disabled={isActionsDisabled || !addSectionTemplate}
              >
                Save
              </StylishNewButton>
            </div>
          </div>
        </div>
      ) : (
        !isSitrepProcessing && (
          <div
            className="add-section"
            disabled={isSitrepProcessing}
            onClick={() => addSectionClicked()}
          >
            <span className="material-symbols-outlined me-2 fs-5">add</span> Add Section
          </div>
        )
      )}

      {/* Footer Buttons */}
      {!addingSection && (
        <div className="tab-footer zIndex--3">
          {isActionsDisabled && (
            <div>
              <i className="fa fa-spinner fa-pulse me-2"></i>Updating Sitrep...
            </div>
          )}
          <div className="d-flex flex-wrap align-items-center">
            <StylishNewButton
              className="button--secondary me-3 mb-2"
              onClick={chatClicked}
              disabled={isActionsDisabled}
            >
              <span className="material-symbols-outlined">chat</span> DisasterChat
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary me-3 mb-2"
              onClick={updateSitrep}
            >
              <span className="material-symbols-outlined">refresh</span> Refresh
            </StylishNewButton>
            <StylishNewButton
              className="button--secondary me-3 mb-2"
              onClick={copySitrepToClipboard}
              disabled={isActionsDisabled}
            >
              Copy to Clipboard
            </StylishNewButton>
            <StylishNewButton
              className="button--primary me-3 mb-2"
              onClick={sendDgptSitrepToInitialIncidentReportSummary}
              disabled={isActionsDisabled}
            >
              <span className="material-symbols-outlined">electric_bolt</span>
              Create Incident Report
            </StylishNewButton>

            {/* "Download Docx" Button */}
            <StylishNewButton
              className="button--secondary me-3 mb-2"
              onClick={downloadSitrepDocx}
              disabled={!sitrep.sections || sitrep.sections.length === 0 || isActionsDisabled}
            >
              <AiOutlineFileWord className="me-2" />
              Download DOCX
            </StylishNewButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sitrep;

import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisasterChats } from './hooks/useDisasterChats';
import DisasterChatItem from './DisasterChatItem';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { setSelectedDChat, setCurrentSitrepId } from 'slices/dchatSlice';
import { IoMdBook } from 'react-icons/io';
import PromptLibraryList from './PromptLibraryList'; // Import the PromptLibraryList component
import { selectGroupGuid } from 'slices/commonSelectors';
import IconClose from 'assets/images/icon__times.svg';
import EntityList from 'components/IAP/EntityList'; // Import EntityList
import { useToggle } from 'react-use'; // Import useToggle if needed

const DisasterChatSidebar = ({ toggleDGPTOpen, DGPTOpen, onClose }) => {
    const dispatch = useDispatch();
    const { data: chats = [], isLoading } = useDisasterChats();
    const group_guid = useSelector(selectGroupGuid);

    const [showPromptLibrary, setShowPromptLibrary] = useState(false); // State to toggle between views
    const [filterText, setFilterText] = useState(''); // Added filterText state
    const [expanded, toggleExpanded] = useToggle(true);

    const handleChatClick = (chat) => {
        const startedOpen = DGPTOpen;
        dispatch(setCurrentSitrepId());
        dispatch(setSelectedDChat(chat));
        toggleDGPTOpen();
        if (startedOpen) {
            toggleDGPTOpen();
        }
    };

    const togglePromptLibrary = () => {
        setShowPromptLibrary((prev) => !prev);
    };

    const handleClickClose = () => {
        onClose();
    }

    const filteredChats = useMemo(() => {
        const lowerCaseFilter = filterText.toLowerCase();
        return chats.filter((chat) => {
            // Check if chat.name exists and is a string
            if (typeof chat.name === 'string') {
                return chat.name.toLowerCase().includes(lowerCaseFilter);
            } else {
                // Optionally, log a warning or handle chats with null/invalid names
                console.warn(`Encountered chat with invalid name:`, chat);
                return false; // Exclude chats without valid names from the filtered list
            }
        });
    }, [chats, filterText]);

    return (
        <div
            className="sidebar-content-wide"
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }} // Added inline CSS here
        >
            <div className="sidebar-title">
                <h4 className="m-0">
                    {showPromptLibrary ? 'Prompt Library' : 'DisasterChats'}
                </h4>
                <StylishNewButton
                    customButton
                    className={'button--icon'}
                    onClick={() => handleClickClose()}
                    >
                    <img src={IconClose} alt="" />
                </StylishNewButton>
            </div>
            <div className="new-chat-icon">
                {!showPromptLibrary && (
                    <StylishNewButton
                        className="button button--link"
                        onClick={() => handleChatClick({})}
                        style={{ margin: '8px' }}
                    >
                        <SharedIcon iconName="add" />
                    </StylishNewButton>
                )}
                <StylishNewButton
                    className="button button--link"
                    onClick={togglePromptLibrary}
                    style={{ margin: '8px', height: '28px', width: '28px' }}
                >
                    <IoMdBook />
                </StylishNewButton>
            </div>

            <hr className="dashed w-10 thin m-0" />

            <div style={{ flexGrow: 1, overflowY: 'auto', height: 'calc(100% - 64px - 44px)' }}>
                {showPromptLibrary ? (
                    <PromptLibraryList group_guid={group_guid} />
                ) : isLoading ? (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>Loading...</span>
                    </div>
                ) : (
                    <>
                        {/* Added filter input */}
                        <div style={{ padding: '8px 16px' }}>
                            <input
                                type="text"
                                placeholder="Filter chats by name"
                                value={filterText}
                                onChange={(e) => setFilterText(e.target.value)}
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </div>
                        {/* Render EntityList with filteredChats */}
                        {filteredChats.length > 0 ? (
                            <EntityList
                                entities={filteredChats}
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                header={<></>} // You can customize the header if needed
                                renderEntity={(chat) => (
                                    <DisasterChatItem
                                        key={chat.dchat_id}
                                        chat={chat}
                                        onClick={() => handleChatClick(chat)}
                                    />
                                )}
                            />
                        ) : (
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <span>No chats found.</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default DisasterChatSidebar;

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconClose from '../../../assets/images/icon__times.svg';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { samplePointLayer } from '../constants/sampleLayers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import { PopupContent } from './MapOilSpillPopupLayer';

// Extend dayjs with the utc plugin
dayjs.extend(utc);

export const oilSpillTableRows = [
  {
    oillspill_level: 1,
    percent_pop: '0-0.05',
    count_pop: '#3686b9ff',
  },
  {
    oillspill_level: 2,
    percent_pop: '0.06-0.15',
    count_pop: '#b1dba6ff',
  },
  {
    oillspill_level: 3,
    percent_pop: '0.16-0.30',
    count_pop: '#9e9e9e',
  },
  {
    oillspill_level: 4,
    percent_pop: '0.31-0.50',
    count_pop: '#f8ac64ff',
  },
  {
    oillspill_level: 5,
    percent_pop: '0.51+',
    count_pop: '#cf1419ff',
  },
];

const oilSpillTableColsStyle = (row) => {
  return {
    padding: '.75rem 1rem',
    fontWeight: '600',
    background:
    row?.oillspill_level === 0
        ? '#ffffff'
        :  row?.oillspill_level === 1
        ? '#3686b9'
        : row?.oillspill_level === 2
        ? '#b1dba6'
        : row?.oillspill_level === 3
        ? '#fffdc1'
        : row?.oillspill_level === 4
        ? '#f8ac64'
        : row?.oillspill_level === 5
        ? '#cf1419'
        : '#57fb31',
    color:
      row?.oillspill_level === 4 || row?.oillspill_level === 5
        ? '#ffffff'
        : '#000000',
  };
};

export const oilSpillTableCols = [
  {
    dataField: 'oillspill_level',
    text: 'Oil Spill Level',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Oil Spill Level' },
    style: (_, row) => {
      return oilSpillTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center',
  },
  {
    dataField: 'percent_pop',
    text: 'Oil Surface Concentration (kg/m^2)',
    sort: true,
    attrs: { title: 'Oil Surface Concentration' },
    style: (_, row) => {
      return oilSpillTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center',
  },
  {
    dataField: 'count_pop',
    text: 'Hex Color',
    sort: true,
    attrs: { title: 'Hex Color' },
    style: (_, row) => {
      return oilSpillTableColsStyle(row);
    },
    headerAlign: 'center',
    align: 'center',
  },
];

const MapOilSpill = (props) => {

  const { 
    map,
    mapHasLoaded,
    apiPrefix,
    onClose,
    mapSettings,
    handleMapSettings
  } = props;

  // Selector variables
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);
  const oilSpillActive = mapSettings?.oilSpillActive;
  // const [oilSpillActive, setoilSpillActive] = useState(false);
  const selectedDatetime = mapSettings?.selectedDatetime;
  const oil_spill_points_source = "oil_spill_points_source";
  const oil_spill_points_layer = "oil_spill_points_layer";
  const oil_spill_points_source_layer = "public.oil_spill_pointers";
  const markerRef = useRef();
  const markerInstance = useRef(null);  // Store the actual marker instance
  
  const basemap = mapSettings?.basemap;

  const oil_spill_points_layer_config = {
    ...samplePointLayer,
    id: oil_spill_points_layer,
    source: oil_spill_points_source,
    'source-layer': oil_spill_points_source_layer,
    generateId: true,
    paint: {
      'circle-stroke-width': 0,
      'circle-color': [
        'step',
        ['get', 'surf_conc'],
        'black',           // Default color 
        0, '#2ba3f0',     // surf_conc >= 0
        0.01, '#3686b9',       // surf_conc >= 0.01
        0.06, '#b1dba6',      // surf_conc >= 0.06
        0.16, '#fffdc1',        // surf_conc >= 0.16
        0.31, '#f8ac64',        // surf_conc >= 0.31
        0.51, '#cf1419'        // surf_conc >= 0.51
            ],
      'circle-opacity': 0.6,
      'circle-radius': 5
    },
    metadata: {
      eventlayer: false,
      usermade: undefined,
      model_layer: false,
      model_details: undefined,
      incident_report: false,
      oil_spill: true
    },
  };

  const oil_spill_points_tiles = `${apiPrefix}/oil_spill_points/${oil_spill_points_source_layer}/{z}/{x}/{y}.pbf`;

  const formattedDatetime = selectedDatetime
      .format('YYYY-MM-DD HH:mm:ss');

  // Handle close button click
  const handleClickClose = () => {
    onClose();
    removeMarker();
  };

  const addMarker = () => {
    if (markerInstance.current) {
      // If marker already exists, don't add again
      return;
    }

    const popupNode = document.createElement('div');

    const root = ReactDOM.createRoot(popupNode);
    root.render(<PopupContent />);

    const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupNode);

    markerInstance.current = new mapboxgl.Marker(markerRef.current)
      .setLngLat([-94.4518, 28.9978])
      .setPopup(popup)
      .addTo(map.current);
  };

  const removeMarker = () => {
    if (markerInstance.current) {
      markerInstance.current.remove();
      markerInstance.current = null; // Reset marker reference
    }
  };

  // useEffect to manage map layers
  useEffect(() => {

    if (map?.current && mapHasLoaded && reduxCurrentlySelectedGroup) {       
      // Remove existing layer and source if they exist
      if (map.current.getLayer(oil_spill_points_layer)) {
        map.current.removeLayer(oil_spill_points_layer);
      }
      if (map.current.getSource(oil_spill_points_source)) {
        map.current.removeSource(oil_spill_points_source);
      }

      // Only add source and layer if toggle is on
      if (oilSpillActive) {
        map.current.addSource(oil_spill_points_source, {
          'type': 'vector', // Use vector if using vector tiles
          'tiles': [
            oil_spill_points_tiles + '?datetime_selected=' + formattedDatetime
          ],
          minzoom: 0,
          maxzoom: 22,
        });
        
        map.current.addLayer(oil_spill_points_layer_config);

        // Map interactions
        map.current.on('click', oil_spill_points_layer_config.id, (e) => {});
        map.current.on('touchend', oil_spill_points_layer_config.id, (e) => {});
        map.current.on('mouseenter', oil_spill_points_layer_config.id, () => {
          map.current.getCanvas().style.cursor = 'pointer';
        });
        map.current.on('mouseleave', oil_spill_points_layer_config.id, () => {
          map.current.getCanvas().style.cursor = 'grab';
        });

        addMarker();
      } else {
        removeMarker();
      }
    }
  }, [
    mapHasLoaded,
    formattedDatetime, // Add formattedDatetime to dependencies
    reduxCurrentlySelectedGroup, // Add reduxCurrentlySelectedGroup to dependencies
    oilSpillActive,
    basemap
  ]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        <h4 className="m-0">Oil Spill</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={handleClickClose}
        >
          <img src={IconClose} alt="Close" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        <div className="d-flex align-items-center mb-3">
          <span>Show Oil Spill</span>
          <StylishSwitcher
            className="ms-auto"
            checked={oilSpillActive}
            onChange={() => {
              // Update mapSettings state with new oillspillActive value
              handleMapSettings({
                ...mapSettings,
                oilSpillActive: !mapSettings.oilSpillActive
              });
            }}
          />
        </div>
        <div className='d-flex'>
          <span className='ms-auto'>
            Oil spill begins 10/28/24 and progresses through 11/2/24.
          </span>
        </div>
        <div className="scroll scroll-events">
          <StylishNewTable
            classes={'tbody-p-0 layout-auto'}
            keyField={`id`}
            rows={oilSpillTableRows}
            columns={oilSpillTableCols}
          />
        </div>
      </div>
    </div>
  );
};

export default MapOilSpill;

import React from 'react';

const LockIcon = () => {
  return (
    <span className="ms-auto ps-3 flex-shrink-0">
      <i className="material-symbols-outlined pratus-lock-icon">lock</i>
    </span>
  );
};

export default LockIcon;

import { NavLink } from 'react-router-dom';
import NavIcon from './NavIcon';
import { ListGroupItem } from 'react-bootstrap';
import React from 'react';
import { useSidebarNavContext } from './SidebarNavProvider';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import LockIcon from '../DesignSystems/LockIcon';

type ListGroupNavItemProps = {
  to: string;
  icon?: string;
  label: string;
  secondary?: boolean;
  end?: boolean;
  isLocked?: boolean;
  isDisabled?: boolean;
  checkWidth?: boolean;
  onClick?: () => void;
};

export const ListGroupNavItem: React.FC<ListGroupNavItemProps> = ({
  to,
  icon,
  label,
  secondary,
  end,
  isLocked,
  isDisabled,
  checkWidth,
  onClick,
}) => {
  const { close, handleShowUpgradeModal } = useSidebarNavContext();
  const { width } = useWindowSize();

  return (
    <>
      {!isLocked ? (
        <ListGroupItem
          className={classNames({ secondary: secondary })}
          as={NavLink}
          to={to}
          onClick={() => {
            if (checkWidth) {
              if (width < 430) {
                close();
              } else if (onClick) {
                onClick();
              }
            } else {
              onClick ? onClick() : close();
            }
          }}
          disabled={isDisabled}
          end={end}
        >
          {!secondary && icon ? <NavIcon icon={icon} /> : null}
          <span className="flex-grow-1">{label}</span>
        </ListGroupItem>
      ) : (
        <ListGroupItem
          className={classNames({ secondary: secondary })}
          onClick={handleShowUpgradeModal}
        >
          {!secondary && icon ? <NavIcon icon={icon} /> : null}
          <span className="flex-grow-1">{label}</span>
          {isLocked ? <LockIcon /> : null}
        </ListGroupItem>
      )}
    </>
  );
};

type ListGroupButtonItemProps = {
  onClick: () => void;
  icon: string;
  label: string;
  isLocked?: boolean;
  isDisabled?: boolean;
};

export const ListGroupButtonItem: React.FC<ListGroupButtonItemProps> = ({
  onClick,
  icon,
  label,
  isLocked,
  isDisabled,
}) => {
  const { handleShowUpgradeModal } = useSidebarNavContext();
  return (
    <ListGroupItem
      as="button"
      onClick={isLocked ? handleShowUpgradeModal : onClick}
      disabled={isDisabled}
    >
      <div className="d-flex align-items-center flex-grow-1">
        <NavIcon icon={icon} />
        <span>{label}</span>
        <i className="material-symbols-outlined m-0 pe-0 ms-auto">
          arrow_right
        </i>
      </div>

      {isLocked ? <LockIcon /> : null}
    </ListGroupItem>
  );
};

type ListGroupDropdownItemProps = {
  onClick: () => void;
  open: boolean;
  icon: string;
  label: string;
  isLocked?: boolean;
};

export const ListGroupDropdownItem: React.FC<ListGroupDropdownItemProps> = ({
  onClick,
  open,
  icon,
  label,
  isLocked,
}) => {
  const { handleShowUpgradeModal } = useSidebarNavContext();
  return (
    <ListGroupItem
      as="button"
      onClick={isLocked ? handleShowUpgradeModal : onClick}
    >
      <div className="d-flex align-items-center flex-grow-1">
        <NavIcon icon={icon} />
        <span>{label}</span>
        <i className="material-symbols-outlined m-0 pe-0 ms-auto">
          {open ? 'arrow_drop_up' : 'arrow_right'}
        </i>
      </div>
      {isLocked ? <LockIcon /> : null}
    </ListGroupItem>
  );
};

// SitrepSidebarItem.js

import React, { useState, useRef, useEffect } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { MdEdit } from 'react-icons/md';
import { useUpdateSitrepV2 } from './hooks/useSitrepsV2'; // Adjust the import path as necessary
import * as dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';

const SitrepSidebarItem = ({ sitrep, onClick }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(sitrep.name);
    const inputRef = useRef(null);
    const updateSitrep = useUpdateSitrepV2(); // Use the updated hook
    const [showArchiveModal, setShowArchiveModal] = useState(false);

    const handleRename = () => {
        const trimmedName = newName.trim();
        if (trimmedName !== '' && trimmedName !== sitrep.name) {
            updateSitrep.mutate({ name: trimmedName, sitrep_id: sitrep.id });
        }
        setIsEditing(false);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing]);

    const handleArchive = () => {
        setShowArchiveModal(true);
    };

    const handleConfirmArchive = () => {
        updateSitrep.mutate({ sitrep_id: sitrep.id, archived: !sitrep.archived });
        setShowArchiveModal(false);
    };

    const handleCancelArchive = () => {
        setShowArchiveModal(false);
    };

    return (
        <li className="sitrep-sidebar-item" style={{ marginBottom: '8px' }}>
            {isEditing ? (
                <input
                    ref={inputRef}
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    onBlur={handleRename}
                    onKeyDown={(e) => e.key === 'Enter' && handleRename()}
                    style={{
                        width: '100%',
                        padding: '8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                    }}
                />
            ) : (
                <StylishNewButton
                    className="button--secondary me-3"
                    onClick={onClick}
                >
                    <span>
                        <span style={{ fontWeight: 'bold', display: 'block', textAlign: 'left' }}>{sitrep.name}</span>
                        <div>
                            <em>Last Modified: </em>{dayjs(new Date(sitrep.timestamp).toLocaleString()).format('YYYY-MM-DD HH:mm UTC')}
                        </div>
                    </span>
                </StylishNewButton>
            )}
            <div className="options" style={{ marginTop: '4px' }}>
                <StylishNewButton
                    className="button button--link"
                    onClick={() => setIsEditing(true)}
                    style={{ marginRight: '8px' }}
                >
                    <MdEdit /> Rename
                </StylishNewButton>
                <StylishNewButton
                    className="button button--link archive"
                    onClick={handleArchive}
                >
                    <SharedIcon iconName={sitrep.archived ? "unarchive" : "archive"} classes="me-2" /> {sitrep.archived ? "Unarchive" : "Archive"}
                </StylishNewButton>
            </div>
            <Modal
                show={showArchiveModal}
                onHide={handleCancelArchive}
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{sitrep.archived ? "Unarchive" : "Archive"} SitRep</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {sitrep.archived ? "Unarchive" : "Archive"} this SitRep session?
                </Modal.Body>
                <Modal.Footer>
                    <StylishNewButton variant="secondary" onClick={handleCancelArchive}>
                        Cancel
                    </StylishNewButton>
                    <StylishNewButton variant={sitrep.archived ? "success" : "warning"} onClick={handleConfirmArchive}>
                        {sitrep.archived ? "Unarchive" : "Archive"}
                    </StylishNewButton>
                </Modal.Footer>
            </Modal>
        </li>
    );
};

export default SitrepSidebarItem;
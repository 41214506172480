// hooks/useSitrepTemplates.js

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useSelector } from 'react-redux';
import { selectGroupGuid } from 'slices/commonSelectors';
import { useSubscriptionLevel } from '../../SubscriptionManagement/useSubscriptionLevel';

export const keys = {
  sitrepTemplates: 'sitrepTemplates',
};

// Fetch SITREP templates
export const useSitrepTemplates = () => {
  const group_guid = useSelector(selectGroupGuid);
  const { paid } = useSubscriptionLevel();

  return useQuery({
    queryKey: [keys.sitrepTemplates, group_guid],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.sitrepTemplates}/`,
        {
          params: { group_guid },
        }
      );
      return response.data;
    },
    onError: (error) => {
      console.error('Error fetching SITREP templates:', error);
      toast.error('Failed to fetch SITREP templates.', {
        ...toastConfig,
        autoClose: false,
      });
    },
    enabled: paid,
  });
};

// Create a SITREP template
export const useCreateSitrepTemplate = () => {
  const queryClient = useQueryClient();
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepTemplates}/`,
        {
          ...data,
          group_guid,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitrepTemplates]);
    },
    onError: (error) => {
      console.error('Error creating SITREP template:', error);
      toast.error('Failed to create SITREP template.', toastConfig);
    },
  });
};

// Update a SITREP template
export const useUpdateSitrepTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const response = await axios.put(
        `${config.apiGateway.sitrepTemplates}/`,
        data
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitrepTemplates]);
    },
    onError: (error) => {
      console.error('Error updating SITREP template:', error);
      toast.error('Failed to update SITREP template.', toastConfig);
    },
  });
};

// Delete a SITREP template
export const useDeleteSitrepTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(
        `${config.apiGateway.sitrepTemplates}/${id}`
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitrepTemplates]);
    },
    onError: (error) => {
      console.error('Error deleting SITREP template:', error);
      toast.error('Failed to delete SITREP template.', toastConfig);
    },
  });
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { selectGroups, useAppSelector } from '../../slices/commonSelectors';

type EditGroupModalProps = {
  onClose: () => void;
  startDelete: () => void;
  selectedGroup: string;
  show: boolean;
};
const DeleteGroupModal: React.FC<EditGroupModalProps> = ({
  onClose,
  startDelete,
  selectedGroup,
  show,
}) => {
  const groups = useAppSelector(selectGroups);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {groups.length === 1 ? (
          <div className="mb-3">
            You cannot delete this group because it is the only group.
          </div>
        ) : (
          <>
            <div className="mb-3">
              Are you sure you want to delete {selectedGroup}?
            </div>
            <div className="mb-3">
              This action cannot be undone, and will delete all organization
              data. This will not delete Incidents linked to this organization.
            </div>
            <div className="form-text form-error multiline mt-4">
              After deleting this organization this page will be reloaded.
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary"
            onClick={onClose}
          >
            No, take me back
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button--error"
            onClick={startDelete}
            disabled={groups.length === 1}
          >
            Yes, I want to delete
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGroupModal;

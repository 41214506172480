// SectionTemplateManager.js

import React, { useState } from 'react';
import {
  useSectionTemplates,
  useCreateSectionTemplate,
  useUpdateSectionTemplate,
  useDeleteSectionTemplate,
} from './hooks/useSectionTemplates';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import EntityList from 'components/IAP/EntityList';
import { useToggle } from 'react-use';
import { MdEdit, MdDelete, MdAdd } from 'react-icons/md';
import SectionTemplateEditDrawer from './SectionTemplateEditDrawer';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

const SectionTemplateManager = () => {
  const { data: sectionTemplates = [], isLoading, isError } = useSectionTemplates();
  const createSectionTemplateMutation = useCreateSectionTemplate();
  const updateSectionTemplateMutation = useUpdateSectionTemplate();
  const deleteSectionTemplateMutation = useDeleteSectionTemplate();
  const [expanded, toggleExpanded] = useToggle(true);

  const [addDrawerOpen, toggleAddDrawer] = useToggle(false);
  const [editDrawerOpen, toggleEditDrawer] = useToggle(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleOpenAddDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer();
  };

  const handleOpenEditDrawer = (template) => {
    setSelectedTemplate(template);
    toggleEditDrawer();
  };

  const handleCloseDrawer = () => {
    setSelectedTemplate(null);
    toggleAddDrawer(false);
    toggleEditDrawer(false);
  };

  const handleSaveTemplate = (templateData) => {
    if (selectedTemplate) {
      // Update existing template
      updateSectionTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('Section template updated successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error updating section template:', error);
          toast.error('Failed to update section template.', toastConfig);
        },
      });
    } else {
      // Create new template
      createSectionTemplateMutation.mutate(templateData, {
        onSuccess: () => {
          toast.success('Section template created successfully.', toastConfig);
          handleCloseDrawer();
        },
        onError: (error) => {
          console.error('Error creating section template:', error);
          toast.error('Failed to create section template.', toastConfig);
        },
      });
    }
  };

  const handleDeleteTemplate = (id) => {
    if (window.confirm('Are you sure you want to delete this Section Template?')) {
      deleteSectionTemplateMutation.mutate(id, {
        onSuccess: () => {
          toast.success('Section template deleted successfully.', toastConfig);
        },
        onError: (error) => {
          console.error('Error deleting section template:', error);
          toast.error('Failed to delete section template.', toastConfig);
        },
      });
    }
  };

  if (isError) {
    return <div className="error-message">Error loading section templates.</div>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <StylishNewButton onClick={handleOpenAddDrawer}>
          <MdAdd className="me-2" />
          Add Section Template
        </StylishNewButton>
      </div>
      {isLoading ? (
        <div>Loading Section Templates...</div>
      ) : (
        <EntityList
          entities={sectionTemplates}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
          renderEntity={(template) => (
            <div
              key={template.id}
              className="d-flex justify-content-between align-items-center p-2 border-bottom"
            >
              <div>
                <strong>{template.name}</strong> ({template.type})
              </div>
              <div>
                <StylishNewButton
                  className="btn-icon me-2"
                  onClick={() => handleOpenEditDrawer(template)}
                >
                  <MdEdit />
                </StylishNewButton>
                <StylishNewButton
                  className="btn-icon"
                  onClick={() => handleDeleteTemplate(template.id)}
                >
                  <MdDelete />
                </StylishNewButton>
              </div>
            </div>
          )}
        />
      )}

      {/* Add/Edit Drawer */}
      {(addDrawerOpen || editDrawerOpen) && (
        <SectionTemplateEditDrawer
          show={addDrawerOpen || editDrawerOpen}
          onHide={handleCloseDrawer}
          onSave={handleSaveTemplate}
          template={selectedTemplate}
        />
      )}
    </div>
  );
};

export default SectionTemplateManager;

// useSitrepsV2.js

import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import {
  selectUserGuid,
  selectGroupGuid,
} from '../../../slices/commonSelectors';

export const keys = {
  base: 'disasterChatsV2',
  sitreps: 'sitrepsV2',
};

// Hook to fetch sitreps (list of sitreps)
export const useSitrepsV2 = () => {
  const group_guid = useSelector(selectGroupGuid);

  return useQuery({
    queryKey: [keys.sitreps, group_guid],
    queryFn: async () => {
      const response = await axios.get(`${config.apiGateway.sitrepsV2}/`, {
        params: { group_guid },
      });
      return response.data;
    },
    staleTime: 0,
    onError: (error) => {
      toast.error('Failed to fetch SITREPs.', {
        ...toastConfig,
        autoClose: false,
      });
    },
  });
};

// Hook to fetch a specific sitrep by ID with polling until status is 'Summarized'
export const useSitrepV2 = (sitrepId) => {
  return useQuery({
    queryKey: [keys.sitreps, sitrepId],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiGateway.sitrepsV2}/pollSitrep`,
        {
          params: { sitrep_id: sitrepId },
        }
      );
      let sitrep = response.data;
      // Check if sitrep status is 'Error', and throw an error if so
      if (sitrep.status && sitrep.status.toLowerCase() === 'error') {
        throw new Error('Sitrep processing failed with status "Error"');
      }
      return sitrep;
    },
    enabled: Boolean(sitrepId), // Only run the query if sitrepId is truthy
    refetchInterval: (data) => {
      let status
      if(data && !!data.queryKey)
      {
        status = data?.state?.data?.status.toLowerCase()
      }
      else
      {
        status = data?.status.toLowerCase()
      }
      if (!status || (status !== 'summarized' && status !== 'error')) {
        return 500; // Continue polling every 500ms
      } else {
        return false; // Stop polling
      }
    },
    retry: false, // Do not retry the query if it fails
    onError: (error) => {
      console.error('Error fetching SITREP:', error);
      toast.error('Failed to fetch SITREP.', {
        ...toastConfig,
        autoClose: false,
      });
    },
  });
};

// Hook to initiate a sitrep
export const useInitSitrepV2 = () => {
  const queryClient = useQueryClient();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);

  return useMutation({
    mutationFn: async ({
      sitrep_template_id,
      sitrep_source,
      sitrep_source_id,
      name,
      selectedDatetime,
    }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/initSitrep`,
        {
          sitrep_template_id,
          sitrep_source,
          sitrep_source_id,
          name,
          user_guid,
          group_guid,
          selectedDatetime,
        }
      );

      if (response.data && response.data.sitrepId) {
        return response.data.sitrepId;
      } else {
        throw new Error('Invalid response from server: sitrepId not found.');
      }
    },
    onSuccess: (sitrepId) => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error initiating SITREP:', error);
      toast.error('Failed to initiate SITREP.', toastConfig);
    },
  });
};

// Hook to update a sitrep (e.g., rename or archive)
export const useUpdateSitrepV2 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ name, sitrep_id, archived }) => {
      const payload = { id: sitrep_id };
      if (name !== undefined) payload.name = name;
      if (archived !== undefined) payload.archived = archived;

      const response = await axios.put(`${config.apiGateway.sitrepsV2}/`, payload);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error updating SITREP:', error);
      toast.error('Failed to update SITREP.', toastConfig);
    },
  });
};

// Hook to refresh a sitrep
export const useRefreshSitrepV2 = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, selectedDatetime, sections=[] }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/refreshSitrep`,
        {
          sitrepId: id,
          user_guid,
          group_guid,
          selectedDatetime,
          sections
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error refreshing SITREP:', error);
      toast.error('Failed to refresh SITREP.', toastConfig);
    },
  });
};

// Hook to update a sitrep section
export const useUpdateSitrepSectionV2 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionId, updatedFields }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/updateSitrepSection`,
        {
          sitrepId,
          sectionId,
          ...updatedFields,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error updating SITREP section:', error);
      toast.error('Failed to update SITREP section.', toastConfig);
    },
  });
};

// Hook to add a sitrep section
export const useAddSitrepSectionV2 = () => {
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useSelector(selectGroupGuid);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionTemplateId, selectedDatetime, sitrep_source, sitrep_source_id, order }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/addSitrepSection`,
        {
          sitrepId,
          sectionTemplateId,
          user_guid,
          group_guid,
          selectedDatetime,
          sitrep_source,
          sitrep_source_id,
          order,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error adding SITREP section:', error);
      toast.error('Failed to add SITREP section.', toastConfig);
    },
  });
};

// Hook to remove a sitrep section
export const useRemoveSitrepSectionV2 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionId }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/removeSitrepSection`,
        {
          sitrepId,
          sectionId,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([keys.sitreps]);
    },
    onError: (error) => {
      console.error('Error removing SITREP section:', error);
      toast.error('Failed to remove SITREP section.', toastConfig);
    },
  });
};


// useSitrepsV2.js

export const useMoveSitrepSectionV2 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ sitrepId, sectionId, direction }) => {
      const response = await axios.post(
        `${config.apiGateway.sitrepsV2}/moveSection`,
        {
          sitrepId,
          sectionId,
          direction,
        }
      );
      return response.data;
    },
    onSuccess: (_, { sitrepId }) => {
      queryClient.invalidateQueries([keys.sitreps, sitrepId]);
    },
    onError: (error) => {
      console.error('Error moving SITREP section:', error);
      toast.error('Failed to move SITREP section.', toastConfig);
    },
  });
};

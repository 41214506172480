// SectionTemplateEditDrawer.js

import React, { useEffect, useState } from 'react';
import { Offcanvas, Row } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import DrawerWrapper, { DrawerFooter } from 'components/IAP/DrawerWrapper';
import FormTextField from 'components/IAP/formFields/FormTextField';
import FormTextAreaField from 'components/IAP/formFields/FormTextAreaField';
import FormSelectField from 'components/IAP/formFields/FormSelectField';
import DataSources from 'components/DisasterGPT/DataSources'; // Import the DataSources component
import { useAppSelector, selectGroupGuid } from '../../slices/commonSelectors';
import Accordion from 'react-bootstrap/Accordion';
import SOPs from 'components/DisasterGPT/SOPs';
import SharepointFiles from 'components/DisasterGPT/SharepointFiles';
import { selectIncident } from 'slices/commonSelectors';


const section_template_types = [
  { label: 'Manual Entry', value: 'manual' },
  { label: 'AI Generated', value: 'ai' },
];

const SectionTemplateEditDrawer = ({ show, onHide, onSave, template }) => {

  const incident = useAppSelector(selectIncident);
  const hasSharepointLocation = incident?.sharepoint_location && incident?.tenant_id;
  const group_guid = useAppSelector(selectGroupGuid);
  const [docSelections, setDocSelections] = useState([]);
  const [sharepointSelections, setSharepointSelections] = useState([]);
  const isUsingTeams =
  sessionStorage['isUsingTeams'] === true ||
  sessionStorage['isUsingTeams'] === 'true';

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      id: '', // Initialize id
      name: '',
      type: 'manual',
      dataSources: [], // This will hold an array of data source objects
      ai_instructions: '',
      output_format: '',
    },
  });

  const { register, handleSubmit, reset, watch, setValue } = methods;
  const selectedType = watch('type');

  useEffect(() => {
    if (template) {
      reset({
        id: template.id || '', // Ensure id is set
        name: template.name || '',
        type: template.type || 'manual',
        dataSources: template.data_sources || [], // Backend sends data_sources as array of objects
        ai_instructions: template.ai_instructions || '',
        output_format: template.output_format || '',
      });
    } else {
      reset({
        id: '',
        name: '',
        type: 'manual',
        dataSources: [],
        ai_instructions: '',
        output_format: '',
      });
    }
  }, [template, reset]);

  const handleSaveTemplate = (formData) => {
    const templateData = {
      id: formData.id, // Ensure id is included for PUT requests
      group_guid,
      name: formData.name,
      type: formData.type,
      data_sources: formData.dataSources, // Send array of objects
      ai_instructions: formData.ai_instructions,
      output_format: formData.output_format,
    };
    onSave(templateData);
  };

  return (
    <DrawerWrapper toggle={onHide} title={template ? 'Edit Section Template' : 'Add Section Template'}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSaveTemplate)} className="d-flex flex-column justify-content-between flex-grow-1">
          <Offcanvas.Body>
            {/* Hidden ID Field */}
            {template && (
              <input type="hidden" {...register('id')} />
            )}

            {/* Section Name Field */}
            <Row className="mb-3">
              <FormTextField
                title="Section Name"
                fieldName="name"
                register={register}
                rules={{ required: 'Section name is required' }}
              />
            </Row>

            {/* Section Type Field */}
            <Row className="mb-3">
              <FormSelectField
                title="Section Type"
                fieldName="type"
                options={section_template_types}
                register={register}
                rules={{ required: 'Section type is required' }}
              />
            </Row>

            {/* Conditionally Render DataSources Component */}
            {selectedType === 'ai' && (
              <>
                <Row className="mb-3">
                  <DataSources
                    selections={watch('dataSources')}
                    setSelections={(newSelections) => setValue('dataSources', newSelections)}
                    usingSitrep={true} // Exclude "SITREP" data source
                  />
                  <Accordion.Item eventKey='1' className='mt-0'>
                    <SOPs selections={docSelections} setSelections={setDocSelections} />
                  </Accordion.Item>
                  {isUsingTeams && (
                  <Accordion.Item eventKey='2' className='mt-0'>
                      <SharepointFiles selections={sharepointSelections} setSelections={setSharepointSelections} hasSharepointLocation={hasSharepointLocation} />
                  </Accordion.Item>
                  )}
                </Row>

                {/* AI Instructions Field */}
                <Row className="mb-3">
                  <FormTextAreaField
                    title="AI Instructions"
                    fieldName="ai_instructions"
                    register={register}
                  />
                </Row>

                {/* Output Format Field */}
                <Row className="mb-3">
                  <FormTextAreaField
                    title="Output Format"
                    fieldName="output_format"
                    register={register}
                  />
                </Row>
              </>
            )}
          </Offcanvas.Body>

          {/* Drawer Footer with Action Buttons */}
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton variant="secondary" onClick={onHide}>
                Cancel
              </StylishNewButton>
              <StylishNewButton variant="primary" type="submit">
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default SectionTemplateEditDrawer;
